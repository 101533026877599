export default class HandEvaluator {
  constructor() {
    this.handRanks = {
      ROYAL_FLUSH: 10,
      STRAIGHT_FLUSH: 9,
      FOUR_OF_A_KIND: 8,
      FULL_HOUSE: 7,
      FLUSH: 6,
      STRAIGHT: 5,
      THREE_OF_A_KIND: 4,
      TWO_PAIR: 3,
      ONE_PAIR: 2,
      HIGH_CARD: 1
    };

    this.rankValues = {
      '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9,
      'T': 10, 'J': 11, 'Q': 12, 'K': 13, 'A': 14
    };
  }

  evaluateHand(cards) {
    // Ensure we have valid cards array
    if (!cards || !Array.isArray(cards) || cards.length === 0) {
      return { name: 'Invalid Hand', rank: 0 };
    }

    // Get rank counts for evaluation
    const rankCounts = this.getRankCounts(cards);
    const ranks = Object.keys(rankCounts).map(Number);
    
    // Sort ranks by count (descending) and then by rank value (descending)
    const sortedRanks = ranks.sort((a, b) => {
      const countDiff = rankCounts[b] - rankCounts[a];
      return countDiff !== 0 ? countDiff : b - a;
    });

    if (this.isRoyalFlush(cards)) 
      return { name: 'Royal Flush', rank: this.handRanks.ROYAL_FLUSH, highCard: 14 };
    
    if (this.isStraightFlush(cards)) 
      return { name: 'Straight Flush', rank: this.handRanks.STRAIGHT_FLUSH, highCard: sortedRanks[0] };
    
    if (this.isFourOfAKind(cards)) 
      return { 
        name: 'Four of a Kind', 
        rank: this.handRanks.FOUR_OF_A_KIND, 
        highCard: this.findHighestCountRank(rankCounts, 4)
      };
    
    if (this.isFullHouse(cards)) 
      return { 
        name: 'Full House', 
        rank: this.handRanks.FULL_HOUSE, 
        highCard: this.findHighestCountRank(rankCounts, 3)
      };
    
    if (this.isFlush(cards)) 
      return { name: 'Flush', rank: this.handRanks.FLUSH, highCard: sortedRanks[0] };
    
    if (this.isStraight(cards)) 
      return { name: 'Straight', rank: this.handRanks.STRAIGHT, highCard: sortedRanks[0] };
    
    if (this.isThreeOfAKind(cards)) 
      return { 
        name: 'Three of a Kind', 
        rank: this.handRanks.THREE_OF_A_KIND, 
        highCard: this.findHighestCountRank(rankCounts, 3)
      };
    
    if (this.isTwoPair(cards)) 
      return { 
        name: 'Two Pair', 
        rank: this.handRanks.TWO_PAIR, 
        highCard: this.findHighestCountRank(rankCounts, 2)
      };
    
    if (this.isOnePair(cards)) 
      return { 
        name: 'One Pair', 
        rank: this.handRanks.ONE_PAIR, 
        highCard: this.findHighestCountRank(rankCounts, 2)
      };
    
    return { name: 'High Card', rank: this.handRanks.HIGH_CARD, highCard: sortedRanks[0] };
  }

  evaluatePlayerHand(playerCards, communityCards = []) {
    // Combine player's hole cards with community cards
    const allCards = [...playerCards, ...communityCards];
    
    

    // Get the best 5-card hand from all available cards
    return this.evaluateHand(allCards);
  }

  calculateWinProbability(playerCards, communityCards = [], allPlayerCards = []) {
    // Validate input
    if (!playerCards || !Array.isArray(playerCards) || playerCards.length !== 2) {
      console.log("Invalid player cards:", playerCards);
      return 0;
    }

    try {
      // Pre-flop calculation remains the same
      if (!communityCards || communityCards.length === 0) {
        return this.calculatePreFlopProbability(playerCards);
      }

      // On the river (5 community cards), calculate absolute winners
      if (communityCards.length === 5) {
        return this.calculateRiverProbability(playerCards, communityCards, allPlayerCards);
      }

      // For other streets, use simulation-based probability
      return this.calculateSimulatedProbability(playerCards, communityCards);
    } catch (error) {
      console.error("Error calculating win probability:", error);
      return 0;
    }
  }

  calculateRiverProbability(playerCards, communityCards, allPlayerCards) {
    // Get all active players' hands
    const playerHand = this.evaluatePlayerHand(playerCards, communityCards);
    const allHandEvaluations = [];

    // Evaluate each player's hand
    allPlayerCards.forEach(cards => {
      if (cards && cards.length === 2) {
        const evaluation = this.evaluatePlayerHand(cards, communityCards);
        allHandEvaluations.push(evaluation);
      }
    });

    // Find the best hand(s)
    let bestHandRank = 0;
    let bestHandCount = 0;
    let isPlayerBestHand = false;

    // First pass: find the best hand rank
    allHandEvaluations.forEach(evaluation => {
      if (evaluation.rank > bestHandRank) {
        bestHandRank = evaluation.rank;
      }
    });

    // Second pass: count how many players have the best hand
    allHandEvaluations.forEach(evaluation => {
      if (evaluation.rank === bestHandRank) {
        bestHandCount++;
      }
    });

    // Check if our player has the best hand
    if (playerHand.rank === bestHandRank) {
      isPlayerBestHand = true;
    }

    // Calculate probability
    if (isPlayerBestHand) {
      if (bestHandCount === 1) {
        // Player has the only best hand
        return 100;
      } else {
        // Player is tied for best hand
        return (100 / bestHandCount);
      }
    }

    // Player doesn't have the best hand
    return 0;
  }

  calculateSimulatedProbability(playerCards, communityCards) {
    let wins = 0;
    const numSimulations = 1000;
    const deck = this.generateDeck([...playerCards, ...communityCards]);

    for (let i = 0; i < numSimulations; i++) {
      const simulationDeck = [...deck];
      const remainingCards = 5 - communityCards.length;
      const simulatedCommunityCards = [
        ...communityCards,
        ...this.drawCards(simulationDeck, remainingCards)
      ];

      const playerHand = this.evaluatePlayerHand(playerCards, simulatedCommunityCards);
      const opponentCards = this.drawCards(simulationDeck, 2);
      const opponentHand = this.evaluatePlayerHand(opponentCards, simulatedCommunityCards);

      if (this.compareHands(playerHand, opponentHand) > 0) {
        wins++;
      }
    }

    return (wins / numSimulations) * 100;
  }

  calculatePreFlopProbability(playerCards) {
    // Basic pre-flop hand strength calculation
    const [card1, card2] = playerCards;
    const rank1 = this.rankValues[card1.rank];
    const rank2 = this.rankValues[card2.rank];
    const suited = card1.suit === card2.suit;

    // Pocket pairs
    if (rank1 === rank2) {
      return Math.min(85, 50 + (rank1 * 2));
    }

    // High cards
    const highCard = Math.max(rank1, rank2);
    const lowCard = Math.min(rank1, rank2);
    const gap = highCard - lowCard;

    let probability = 40; // Base probability

    // Adjust for high cards
    if (highCard >= 14) probability += 15; // Ace
    else if (highCard >= 13) probability += 10; // King
    else if (highCard >= 12) probability += 5; // Queen

    // Adjust for suited cards
    if (suited) probability += 10;

    // Adjust for connected cards
    if (gap === 1) probability += 5;
    else if (gap === 2) probability += 3;
    else probability -= Math.min(15, gap * 2);

    return Math.min(Math.max(probability, 20), 85);
  }

  generateDeck(excludeCards = []) {
    const deck = [];
    const suits = ['h', 'd', 'c', 's'];
    
    for (const rank in this.rankValues) {
      for (const suit of suits) {
        const card = { rank, suit };
        if (!this.isCardInArray(card, excludeCards)) {
          deck.push(card);
        }
      }
    }
    
    return deck;
  }

  drawCards(deck, num) {
    if (!deck || !Array.isArray(deck) || deck.length < num) {
      console.error("Invalid deck or not enough cards:", deck, num);
      return [];
    }

    const drawnCards = [];
    const deckCopy = [...deck];

    for (let i = 0; i < num; i++) {
      const index = Math.floor(Math.random() * deckCopy.length);
      drawnCards.push(deckCopy.splice(index, 1)[0]);
    }

    return drawnCards;
  }

  isCardInArray(card, array) {
    return array.some(c => c.rank === card.rank && c.suit === card.suit);
  }

  isRoyalFlush(cards) {
    return this.isStraightFlush(cards) && this.getHighCard(cards) === 14;
  }

  isStraightFlush(cards) {
    return this.isFlush(cards) && this.isStraight(cards);
  }

  isFourOfAKind(cards) {
    return this.hasNOfAKind(cards, 4);
  }

  isFullHouse(cards) {
    const rankCounts = this.getRankCounts(cards);
    return Object.values(rankCounts).includes(3) && Object.values(rankCounts).includes(2);
  }

  isFlush(cards) {
    const suitCounts = this.getSuitCounts(cards);
    return Object.values(suitCounts).some(count => count >= 5);
  }

  isStraight(cards) {
    const ranks = [...new Set(cards.map(card => this.rankValues[card.rank]))].sort((a, b) => a - b);
    for (let i = 0; i <= ranks.length - 5; i++) {
      if (ranks[i + 4] - ranks[i] === 4) return true;
    }
    return ranks.includes(14) && ranks.slice(0, 4).join('') === '2345';
  }

  isThreeOfAKind(cards) {
    return this.hasNOfAKind(cards, 3);
  }

  isTwoPair(cards) {
    const rankCounts = this.getRankCounts(cards);
    return Object.values(rankCounts).filter(count => count === 2).length === 2;
  }

  isOnePair(cards) {
    return this.hasNOfAKind(cards, 2);
  }

  hasNOfAKind(cards, n) {
    const rankCounts = this.getRankCounts(cards);
    return Object.values(rankCounts).some(count => count === n);
  }

  getRankCounts(cards) {
    return cards.reduce((counts, card) => {
      if (card && card.rank) {
        const rankValue = this.rankValues[card.rank];
        counts[rankValue] = (counts[rankValue] || 0) + 1;
      }
      return counts;
    }, {});
  }

  getSuitCounts(cards) {
    return cards.reduce((counts, card) => {
      counts[card.suit] = (counts[card.suit] || 0) + 1;
      return counts;
    }, {});
  }

  getHighCard(cards) {
    return Math.max(...cards.map(card => this.rankValues[card.rank]));
  }

  compareHands(hand1, hand2) {
    if (hand1.rank !== hand2.rank) {
      return hand1.rank - hand2.rank;
    }
    return hand1.highCard - hand2.highCard;
  }

  // Helper method to find the highest rank with a specific count
  findHighestCountRank(rankCounts, targetCount) {
    let highestRank = 0;
    for (const [rank, count] of Object.entries(rankCounts)) {
      if (count === targetCount && Number(rank) > highestRank) {
        highestRank = Number(rank);
      }
    }
    return highestRank;
  }
} 