import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { login, register } = useAuth();

  // Check if we came from a game invite
  const redirectPath = location.state?.from || localStorage.getItem('redirectAfterLogin');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (isLogin) {
        const result = await login(email, password);
        if (result.success) {
          if (redirectPath) {
            localStorage.removeItem('redirectAfterLogin');
            navigate(redirectPath);
          } else {
            navigate('/lobby');
          }
        }
      } else {
        if (!username.trim()) {
          setError('Username is required');
          return;
        }
        const result = await register(email, password, username);
        if (result.success) {
          if (redirectPath) {
            localStorage.removeItem('redirectAfterLogin');
            navigate(redirectPath);
          } else {
            navigate('/lobby');
          }
        }
      }
    } catch (error) {
      console.error('Auth error:', error);
      setError(error.message || 'Authentication failed');
    }
  };

  return (
    <AuthWrapper>
      <AuthForm onSubmit={handleSubmit}>
        <h2>{isLogin ? 'Login' : 'Register'}</h2>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        
        {redirectPath && (
          <RedirectMessage>
            Please {isLogin ? 'log in' : 'register'} to join the poker game
          </RedirectMessage>
        )}

        {!isLogin && (
          <FormGroup>
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Choose a display name"
              required={!isLogin}
              minLength={3}
              maxLength={20}
              pattern="[A-Za-z0-9_]+"
              title="Username can only contain letters, numbers, and underscores"
            />
          </FormGroup>
        )}
        
        <FormGroup>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email"
          />
        </FormGroup>
        
        <FormGroup>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            minLength={6}
            placeholder="Enter your password"
          />
        </FormGroup>
        
        <SubmitButton type="submit">
          {isLogin ? 'Login' : 'Register'}
        </SubmitButton>
        
        <ToggleButton type="button" onClick={() => {
          setIsLogin(!isLogin);
          setError('');
          setUsername('');
        }}>
          {isLogin ? 'Need an account? Register' : 'Have an account? Login'}
        </ToggleButton>
      </AuthForm>
    </AuthWrapper>
  );
};

const AuthWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #1a1a1a;
`;

const AuthForm = styled.form`
  background: #2d2d2d;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  color: white;

  h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    text-align: center;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #ccc;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #444;
    border-radius: 4px;
    background: #333;
    color: white;
    font-size: 1rem;
    transition: border-color 0.3s ease;

    &:focus {
      outline: none;
      border-color: #4caf50;
    }

    &::placeholder {
      color: #666;
    }
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background: #388e3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1rem;
  font-size: 1rem;
  transition: background 0.3s ease;

  &:hover {
    background: #2e7d32;
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ToggleButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background: transparent;
  color: #4caf50;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  color: #f44336;
  margin-bottom: 1rem;
  text-align: center;
`;

const RedirectMessage = styled.div`
  background: rgba(52, 152, 219, 0.1);
  border: 1px solid rgba(52, 152, 219, 0.3);
  color: #3498db;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 1rem;
  text-align: center;
`;

export default Auth; 