import React from 'react';
import styled from 'styled-components';

const ChipWrapper = styled.div`
  position: relative;
  width: ${props => props.size === 'tiny' ? '20px' : props.size === 'small' ? '28px' : '36px'};
  height: ${props => props.size === 'tiny' ? '20px' : props.size === 'small' ? '28px' : '36px'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none; /* No background to avoid inheritance */
  border: 2px solid ${props => props.color || '#000'}; /* Border color based on chip color */
  
  /* Inner circle */
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: 75%;
    border-radius: 50%;
    background: ${props => props.color || '#000'};
  }

  /* Value text */
  span {
    position: relative;
    z-index: 2;
    font-size: ${props => props.size === 'tiny' ? '8px' : props.size === 'small' ? '10px' : '12px'};
    font-weight: bold;
    color: white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  }
`;

const getDenominationColor = (amount) => {
  if (amount >= 5000) return '#2C2C2C';  // Black
  if (amount >= 1000) return '#654321';  // Brown
  if (amount >= 500) return '#4B0082';   // Purple
  if (amount >= 100) return '#1B1B4B';   // Navy
  if (amount >= 50) return '#004225';    // Green
  if (amount >= 25) return '#CD7F32';    // Bronze
  if (amount >= 10) return '#1E90FF';    // Blue
  if (amount >= 5) return '#B22222';     // Red
  return '#808080';                      // Gray
};

const Chip = ({ amount, color, size = 'normal' }) => (
  <ChipWrapper 
    color={color || getDenominationColor(amount)} 
    size={size}
  >
    <span>{amount}</span>
  </ChipWrapper>
);

export default Chip; 