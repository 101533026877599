import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import { useAuth } from '../../contexts/AuthContext';
import PlayerSeat from '../PlayerSeat/PlayerSeat';
import ActionButtons from '../ActionButtons/ActionButtons';
import config from '../../config';
import styled, { keyframes } from 'styled-components';
import Card from '../Card/Card';
import CountdownTimer from '../CountdownTimer/CountdownTimer';
import { GAME_STATUS } from '../../utils/gameState';
import { SEAT_POSITIONS, MAX_SEATS } from '../../constants/gameConstants';
import WinnerCard from '../WinnerCard/WinnerCard';
import { CommunityCards } from '../CommunityCards/CommunityCards';
import soundManager from '../../utils/SoundManager';
import GameMenu from '../GameMenu/GameMenu';
import { Button } from '../ui/Button';    // Changed from '../Button/Button'
import { Modal } from '../ui/Modal';      // Changed from '../Modal/Modal'


const TableWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #1a1a1a;
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const TableFelt = styled.div`
  width: 800px;
  height: 400px;
  background: #267f00;
  border-radius: 200px;
  position: relative;
  border: 15px solid #4d2800;
  margin: 50px;

  @media (max-width: 768px) {
    width: 100%;
    height: calc(100vh - 120px); // Full height minus action buttons and some padding
    margin: 10px;
    border-width: 8px;
    border-radius: 30px;
  }
`;

const CommunityCardsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 10px;
`;

const LoadingMessage = styled.div`
  color: white;
  font-size: 24px;
  text-align: center;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 24px;
  text-align: center;
`;

const ChipIcon = styled.svg`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  vertical-align: middle;
  fill: currentColor;
`;

const PotInfo = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ChipStack = styled.div`
  position: relative;
  width: 30px;
  height: ${props => Math.max(30, props.chipCount * 3)}px;
  margin-bottom: 5px;
`;

const StyledChip = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${props => props.color};
  border: 2px solid #fff;
  bottom: ${props => props.index * 3}px;
  box-shadow: 
    inset 0 0 8px rgba(0,0,0,0.5),
    0 1px 1px rgba(0,0,0,0.3);
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
    border-radius: 50%;
    border: 2px solid rgba(255,255,255,0.5);
  }
`;

const getChipColor = (index) => {
  const colors = [
    '#FF0000', // Red for high value
    '#000000', // Black
    '#0000FF', // Blue
    '#008000', // Green
    '#FFA500'  // Orange
  ];
  return colors[index % colors.length];
};

const fadeInShowdown = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`;

const ShowdownOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #ffd700;
  color: white;
  text-align: center;
  z-index: 1002;
  min-width: 300px;
  opacity: 0;
  animation: ${fadeInShowdown} 0.5s ease-out forwards;
  animation-delay: 2s; // 2 second delay
`;

const WinnerInfo = styled.div`
  margin: 10px 0;
  padding: 10px;
  background: rgba(255, 215, 0, 0.1);
  border-radius: 5px;
`;

const ShowdownTitle = styled.h2`
  color: #ffd700;
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const CardDisplay = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 0.5rem 0;
`;

const ActionButtonsContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(26, 26, 26, 0.95);
  padding: 10px;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const CountdownContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px 40px;
  border-radius: 10px;
  border: 2px solid #4d2800;
  min-width: 300px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
`;

const SpectatorBadge = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  z-index: 1000;
  border: 1px solid #ffd700;
`;

const InactivePlayerActions = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(26, 26, 26, 0.95);
  padding: 10px;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;


const ActionButton = styled(Button)`
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  
  &.rejoin {
    background: #4CAF50;
    &:hover {
      background: #45a049;
    }
  }
  
  &.buyin {
    background: #2196F3;
    &:hover {
      background: #1976D2;
    }
  }
`;

const cleanPlayerData = (player) => {
  if (!player) return null;

  return {
    userId: player.userId || player._doc?.userId,
    username: player.username || player._doc?.username,
    seatIndex: typeof player.seatIndex === 'number' ? player.seatIndex : 
               typeof player._doc?.seatIndex === 'number' ? player._doc.seatIndex : 0,
    chips: player.chips || player._doc?.chips || 0,
    currentBet: player.currentBet || player._doc?.currentBet || 0,
    folded: player.folded || player._doc?.folded || false,
    isAllIn: player.isAllIn || player._doc?.isAllIn || false,
    isHost: player.isHost || player._doc?.isHost || false,
    cards: player.cards || player._doc?.cards || [],
    lastAction: player.lastAction || player._doc?.lastAction,
    isActive: player.isActive || player._doc?.isActive
  };
};

const ShowdownResult = ({ winners, show }) => {
  const [shouldRender, setShouldRender] = useState(false);
  
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setShouldRender(true);
      }, 100);
      return () => clearTimeout(timer);
    } else {
      setShouldRender(false);
    }
  }, [show]);

  if (!winners || !show || !shouldRender) {
    return null;
  }
  
  const isWinByFold = winners.some(winner => winner.handName === 'Default Win');
  
  return (
    <ShowdownOverlay>
      <ShowdownTitle>
        {isWinByFold ? 'Winner by Fold!' : 'Showdown Results!'}
      </ShowdownTitle>
      {winners.map((winner, index) => (
        <WinnerInfo key={index}>
          <h3>{winner.username}</h3>
          <p>Won: {winner.amount}</p>
          {!isWinByFold && winner.handName && winner.handName !== 'Default Win' && (
            <>
              <p>Hand: {winner.handName}</p>
              <CardDisplay>
                {winner.cards && winner.cards.map((card, cardIndex) => (
                  <WinnerCard 
                    key={cardIndex}
                    rank={card.rank}
                    suit={card.suit}
                  />
                ))}
              </CardDisplay>
            </>
          )}
        </WinnerInfo>
      ))}
    </ShowdownOverlay>
  );
};

const PlayerChips = styled.div`
  color: #4caf50;
  font-size: 0.8rem;
  margin-top: 2px;
  display: flex;
  align-items: center;
  
  &::before {
    content: '';
    display: inline-block;
    margin-right: 4px;
  }
`;

// Function to determine the number of chips to display
const getChipStack = (potSize) => {
  const chipValue = 100; // Each chip represents 100 units
  const maxChips = 10; // Maximum number of chips to display
  const chipCount = Math.min(Math.floor(potSize / chipValue), maxChips);
  return Array.from({ length: chipCount }, (_, i) => i);
};

const Table = () => {
  const { gameId } = useParams();
  const { user } = useAuth();
  const [gameState, setGameState] = useState(null);
  const [socket, setSocket] = useState(null);
  const [showCountdown, setShowCountdown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [showdownResults, setShowdownResults] = useState(null);
  const [countdownStartTime, setCountdownStartTime] = useState(null);
  const [isSoundEnabled, setIsSoundEnabled] = useState(true);
  const [showdownCards, setShowdownCards] = useState(null);
  const [showBuyinModal, setShowBuyinModal] = useState(false);
  const [buyinAmount, setBuyinAmount] = useState('');
  const [buyinError, setBuyinError] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusMessage, setStatusMessage] = useState({ title: '', message: '', type: '' });

  // Compute derived state
  const currentPlayerIndex = useMemo(() => 
    gameState?.players?.findIndex(p => p.userId === user.id) ?? -1,
    [gameState?.players, user.id]
  );

  const currentPlayer = useMemo(() => 
    gameState?.players?.[currentPlayerIndex],
    [gameState?.players, currentPlayerIndex]
  );

  const isCurrentPlayerActive = useMemo(() => {
    if (!gameState || typeof gameState.activePlayerIndex !== 'number') {
      console.log('Invalid game state or activePlayerIndex');
      return false;
    }

    const activePlayer = gameState.players[gameState.activePlayerIndex];
    const isActive = activePlayer?.userId === user.id;

    console.log('Active player calculation:', {
      activePlayerIndex: gameState.activePlayerIndex,
      activePlayerId: activePlayer?.userId,
      currentUserId: user.id,
      isActive
    });

    return isActive;
  }, [gameState, user.id]);

  // Add this near the top of the component where other useMemo hooks are
  const activePlayerDebug = useMemo(() => {
    if (!gameState) return null;
    
    return {
      activePlayerIndex: gameState.activePlayerIndex,
      activePlayer: gameState.players[gameState.activePlayerIndex],
      currentUserId: user.id,
      isCurrentPlayerActive: gameState?.activePlayerIndex !== null && 
        gameState?.players?.[gameState.activePlayerIndex]?.userId === user.id,
      allPlayers: gameState.players.map(p => ({
        userId: p.userId,
        username: p.username,
        isActive: gameState.activePlayerIndex === p.seatIndex
      }))
    };
  }, [gameState, user.id]);

  // Add this near your other useMemo hooks
  const gameStateDebug = useMemo(() => {
    if (!gameState) return null;
    
    return {
      status: gameState.status,
      phase: gameState.phase,
      activePlayerIndex: gameState.activePlayerIndex,
      players: gameState.players.map(p => ({
        username: p.username,
        userId: p.userId,
        seatIndex: p.seatIndex,
        isActive: gameState.activePlayerIndex === p.seatIndex
      })),
      currentPlayer: gameState.players[gameState.activePlayerIndex],
      isUserActive: gameState.players[gameState.activePlayerIndex]?.userId === user.id
    };
  }, [gameState, user.id]);

  // Event Handlers
  const handleCountdownComplete = useCallback(() => {
    if (!socket) return;
    console.log('Countdown complete, starting game...');
    setShowCountdown(false);
    socket.emit('startGame', { gameId });
  }, [socket, gameId]);

  const handleAction = useCallback((actionPayload) => {
    if (!socket || !gameState) {
      console.error('Action blocked: Missing socket or game state');
      return;
    }
    
    if (!isCurrentPlayerActive) {
      console.error('Action blocked: Not active player');
      return;
    }
    
    console.log('Sending player action:', actionPayload);
    
    socket.emit('playerAction', {
      gameId,
      playerId: user.id,
      action: actionPayload.action,
      amount: actionPayload.amount,
      socketId: socket.id
    });
  }, [socket, gameId, user.id, isCurrentPlayerActive, gameState]);

  // Socket connection and game state management
  useEffect(() => {
    if (!gameId || !user?.id) return;

    console.log('Initializing socket connection for game:', gameId);
    const newSocket = io(config.apiUrl, {
      withCredentials: true,
      query: { gameId, userId: user.id }
    });

    newSocket.on('connect', () => {
      console.log('Socket connected, joining game');
      newSocket.emit('joinGame', {
        gameId,
        playerId: user.id
      });
    });

    newSocket.on('gameState', (newGameState) => {
      console.log('Detailed GameState Debug:', {
        hasActivePlayerIndex: 'activePlayerIndex' in newGameState,
        activePlayerIndex: newGameState.activePlayerIndex,
        activePlayerType: typeof newGameState.activePlayerIndex,
        rawGameState: newGameState,
        players: newGameState.players.map(p => ({
          username: p.username,
          seatIndex: p.seatIndex,
          isActivePlayer: p.seatIndex === newGameState.activePlayerIndex
        }))
      });

      if (newGameState.phase === 'SHOWDOWN') {
        setIsTransitioning(true);
        if (newGameState.winners) {
          setShowdownResults(newGameState.winners);
        }
        
        setTimeout(() => {
          setIsTransitioning(false);
        }, 5300);
      }

      if (typeof newGameState.activePlayerIndex === 'undefined') {
        console.error('Missing activePlayerIndex in game state!');
      }

      setGameState(newGameState);
      setIsLoading(false);
    });

    newSocket.on('error', (error) => {
      console.error('Socket error:', error);
      alert(error.message);
    });

    newSocket.on('buyinRequest', (data) => {
      console.log('Received buyinRequest event - Detailed:', {
        data,
        currentUserId: user?.id,
        hostIdFromData: data.hostId,
        isMatch: user?.id === data.hostId,
        typeCurrentUser: typeof user?.id,
        typeHostId: typeof data.hostId,
        stringComparison: user?.id.toString() === data.hostId.toString()
      });

      if (user?.id.toString() === data.hostId.toString()) {  // Use toString() for comparison
        console.log('Current user is host, showing buyin request modal');
        setStatusMessage({
          title: 'Buy-in Request',
          message: `${data.username} requested a buy-in of ${data.amount} chips`,
          type: 'info',
          buyinId: data.buyinId,
          requestingUserId: data.userId
        });
        setShowStatusModal(true);
      } else {
        console.log('Current user is not host, ignoring buyin request');
      }
    });

    newSocket.on('notification', (notification) => {
      setStatusMessage({
        title: notification.type === 'success' ? 'Success' : 'Notification',
        message: notification.message,
        type: notification.type
      });
      setShowStatusModal(true);
    });

    setSocket(newSocket);

    return () => {
      console.log('Cleaning up socket connection');
      if (newSocket.connected) {
        newSocket.disconnect();
      }
    };
  }, [gameId, user?.id]);

  // Countdown effect
  useEffect(() => {
    const activePlayersCount = gameState?.players?.filter(p => p.isActive)?.length || 0;
    const shouldShowCountdown = 
      activePlayersCount >= 2 && 
      gameState.status === GAME_STATUS.WAITING;

    if (shouldShowCountdown && !showCountdown) {
      console.log('Starting countdown...', {
        activePlayerCount: activePlayersCount,
        status: gameState.status
      });
      setShowCountdown(true);
      setCountdownStartTime(Date.now());
    } else if (gameState?.status === GAME_STATUS.PLAYING) {
      setShowCountdown(false);
      setCountdownStartTime(null);
    }
  }, [gameState?.players, gameState?.status, showCountdown]);

  // Simplified position adjustment using seatIndex
  const getAdjustedPosition = (rawPlayer) => {
    // If no player in this seat, maintain the original position for empty seats
    if (!rawPlayer) {
      return rawPlayer?.seatIndex || 0;
    }

    const player = cleanPlayerData(rawPlayer);
    
    // For spectators, use position 0 as reference point
    const positions = [0, 1, 2, 3, 4, 5];
    let offset = 0;

    if (isSpectator) {
      // Find first occupied seat to use as reference point
      const firstPlayer = gameState.players[0];
      if (firstPlayer) {
        offset = positions.indexOf(firstPlayer.seatIndex);
      }
    } else {
      // Use current player's seat as reference
      const currentUserIndex = gameState?.players.findIndex(p => p.userId === user.id);
      if (currentUserIndex !== -1) {
        offset = positions.indexOf(gameState.players[currentUserIndex].seatIndex);
      }
    }

    // Rotate positions array
    const rotatedPositions = [
      ...positions.slice(offset),
      ...positions.slice(0, offset)
    ];
    
    // Find the index of player's seat in original positions
    const playerPositionIndex = positions.indexOf(player.seatIndex);
    // Get the rotated position
    const relativePosition = rotatedPositions.indexOf(positions[playerPositionIndex]);

    return relativePosition;
  };

  // Add effect to handle phase transitions and showdown
  useEffect(() => {
    if (gameState?.phase === 'SHOWDOWN') {
      setIsTransitioning(true);
      // Make sure to set showdown results for both normal showdown and fold wins
      if (gameState.winners && gameState.winners.length > 0) {
        console.log('Setting showdown results:', gameState.winners);
        setShowdownResults(gameState.winners);
      }
      
      // Clear showdown display after timeout
      const showdownTimer = setTimeout(() => {
        setIsTransitioning(false);
        setShowdownResults(null);
      }, 5000);
      
      return () => clearTimeout(showdownTimer);
    }
  }, [gameState?.phase, gameState?.winners]);

  // Add sound effects for player actions
  useEffect(() => {
    if (!isSoundEnabled) return;

    const currentPlayer = gameState?.players?.[gameState.activePlayerIndex];
    if (currentPlayer?.lastAction) {
      const action = currentPlayer.lastAction.toLowerCase();
      switch (action) {
        case 'check':
          soundManager.play('check');
          break;
        case 'call':
          soundManager.play('call');
          break;
        case 'raise':
        case 'bet':
          soundManager.play('raise');
          break;
        case 'fold':
          soundManager.play('fold');
          break;
        case 'allin':
          soundManager.play('allIn');
          break;
      }
    }
  }, [gameState?.players, gameState?.activePlayerIndex, isSoundEnabled]);

  // Add sound for dealing cards
  useEffect(() => {
    if (!isSoundEnabled) return;

    if (gameState?.phase === 'DEALING') {
      soundManager.play('shuffle');
      setTimeout(() => soundManager.play('cardDeal'), 500);
    }
  }, [gameState?.phase, isSoundEnabled]);

  // Add sound for community cards
  useEffect(() => {
    if (!isSoundEnabled) return;

    if (gameState?.communityCards?.length > 0) {
      soundManager.play('cardDeal');
    }
  }, [gameState?.communityCards?.length, isSoundEnabled]);

  // Add sound for winners
  useEffect(() => {
    if (!isSoundEnabled) return;

    if (gameState?.phase === 'SHOWDOWN' && gameState.winners?.length > 0) {
      soundManager.play('win');
    }
  }, [gameState?.phase, gameState?.winners, isSoundEnabled]);

  // Add effect to fetch hand history during showdown
  useEffect(() => {
    if (gameState?.phase === 'SHOWDOWN') {
      // Check if it's a win by fold (only one active player)
      const activePlayersCount = gameState.players.filter(p => 
        p.isActive && !p.folded
      ).length;

      console.log('Active players in showdown:', activePlayersCount);

      // Only fetch hand history if it's not a win by fold
      if (activePlayersCount > 1) {
        console.log('Multiple active players, fetching hand history');
        fetch(`${config.apiUrl}/api/games/${gameId}/hand-history/latest`, {
          credentials: 'include'
        })
          .then(res => res.json())
          .then(data => {
            if (data.players) {
              const cardsMap = data.players.reduce((acc, player) => {
                acc[player.userId] = player.cards;
                return acc;
              }, {});
              setShowdownCards(cardsMap);
            }
          })
          .catch(err => console.error('Error:', err));
      } else {
        console.log('Win by fold, not revealing cards');
        setShowdownCards(null);
      }
    } else {
      setShowdownCards(null);
    }
  }, [gameState?.phase, gameId]);

  const handleRejoin = () => {
    if (!socket) return;
    
    console.log('Attempting to rejoin with data:', {
      gameId,
      playerId: user.id
    });

    socket.emit('rejoinGame', { 
      gameId,
      playerId: user.id
    });
  };

  const handleBuyinRequest = async () => {
    if (!buyinAmount || isNaN(buyinAmount)) {
      setBuyinError('Please enter a valid amount');
      return;
    }

    const amount = parseInt(buyinAmount);
    if (amount < (gameState?.settings?.bigBlind || 0)) {
      setBuyinError(`Minimum buy-in is ${gameState?.settings?.bigBlind} chips`);
      return;
    }

    try {
      const response = await fetch(`${config.apiUrl}/api/games/${gameId}/buyins`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ amount })
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Failed to request buy-in');

      setShowBuyinModal(false);
      setBuyinAmount('');
      setBuyinError(null);
      
      // Show success message
      setStatusMessage({
        type: 'success',
        message: 'Buy-in request sent successfully'
      });
      setShowStatusModal(true);
    } catch (error) {
      setBuyinError(error.message || 'Failed to request buy-in');
    }
  };

  const handleBuyinApproval = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/api/games/${gameId}/buyins/${statusMessage.buyinId}/approve`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          gameId,
          buyinId: statusMessage.buyinId,
          userId: statusMessage.requestingUserId
        })
      });
      
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Failed to approve buy-in');
      
      setShowStatusModal(false);
      // Emit socket event to notify approval
      if (socket) {
        socket.emit('buyinResponse', {
          buyinId: statusMessage.buyinId,
          userId: statusMessage.requestingUserId,
          approved: true
        });
      }
    } catch (error) {
      console.error('Error approving buy-in:', error);
      setStatusMessage({
        type: 'error',
        message: error.message || 'Failed to approve buy-in'
      });
    }
  };

  const handleBuyinReject = () => {
    if (!socket) {
      console.error('No socket connection');
      return;
    }

    socket.emit('buyinResponse', {
      buyinId: statusMessage.buyinId,
      userId: statusMessage.requestingUserId,
      approved: false
    });
    
    setShowStatusModal(false);
  };

  const isInactivePlayer = useMemo(() => {
    if (!gameState?.players || !user?.id) return false;
    const player = gameState.players.find(p => p.userId === user.id);
    return player && !player.isActive;
  }, [gameState?.players, user?.id]);

  const hasEnoughChips = useMemo(() => {
    if (!gameState?.players || !user?.id) return false;
    const player = gameState.players.find(p => p.userId === user.id);
    return player && player.chips >= (gameState.settings?.bigBlind || 0);
  }, [gameState?.players, user?.id, gameState?.settings?.bigBlind]);

  const renderSeats = () => {
    console.log('Current showdown cards state:', showdownCards);
    
    return SEAT_POSITIONS.map(seatIndex => {
      const rawPlayer = gameState?.players.find(p => p.seatIndex === seatIndex);
      const playerShowdownCards = showdownCards && rawPlayer ? 
        showdownCards[rawPlayer.userId] : null;
      
      console.log('Rendering seat:', {
        seatIndex,
        playerUsername: rawPlayer?.username,
        hasShowdownCards: !!playerShowdownCards,
        showdownCards: playerShowdownCards,
        phase: gameState?.phase
      });
      
      const adjustedPosition = getAdjustedPosition(rawPlayer ? { ...rawPlayer, seatIndex } : { seatIndex });
      const isActivePlayer = rawPlayer && rawPlayer.isActive && !rawPlayer.folded;
      
      return (
        <PlayerSeat
          key={seatIndex}
          player={rawPlayer ? {
            ...cleanPlayerData(rawPlayer),
            // Override cards with showdown cards if available
            cards: gameState.phase === 'SHOWDOWN' && playerShowdownCards ? 
              playerShowdownCards : rawPlayer.cards
          } : null}
          position={adjustedPosition}
          isCurrentPlayer={rawPlayer?.userId === user.id}
          isDealer={gameState?.dealerPosition === seatIndex}
          isActive={gameState?.activePlayerIndex === seatIndex}
          seatIndex={seatIndex}
          isShowdown={gameState?.phase === 'SHOWDOWN'}
          isActivePlayer={isActivePlayer}
        />
      );
    });
  };

  const isSpectator = useMemo(() => {
    if (!gameState?.players || !user?.id) return true;
    return !gameState.players.some(p => p.userId === user.id);
  }, [gameState?.players, user?.id]);

  if (isLoading) return <LoadingMessage>Loading game...</LoadingMessage>;
  if (!gameState) return <ErrorMessage>Failed to load game state</ErrorMessage>;

  console.log('Action buttons visibility:', {
    isCurrentPlayerActive,
    activePlayerIndex: gameState.activePlayerIndex,
    currentPlayerId: user.id,
    activePlayerId: gameState.players[gameState.activePlayerIndex]?.userId
  });

  return (
    <TableWrapper>
      <GameMenu 
        currentPot={gameState?.pot || 0}
        isSoundEnabled={isSoundEnabled}
        setIsSoundEnabled={setIsSoundEnabled}
        gameId={gameId}
      />
      
      {isSpectator && (
        <SpectatorBadge>
          Spectating
        </SpectatorBadge>
      )}

      {showCountdown && (
        <CountdownContainer>
          <CountdownTimer 
            seconds={5} 
            onComplete={handleCountdownComplete}
            startTime={countdownStartTime}
          />
        </CountdownContainer>
      )}
      
     

      <Modal
        isOpen={showBuyinModal}
        onClose={() => {
          setShowBuyinModal(false);
          setBuyinAmount('');
          setBuyinError(null);
        }}
        title="Request Buy In"
      >
        <div style={{ padding: '20px' }}>
          <p>Minimum buy-in: {gameState?.settings?.bigBlind} chips</p>
          <div style={{ marginBottom: '15px' }}>
            <label style={{ color: 'white' }}>
              Buy In Amount:
              <input
                type="number"
                value={buyinAmount}
                onChange={(e) => setBuyinAmount(e.target.value)}
                min={gameState?.settings?.bigBlind || 0}
                style={{
                  width: '100%',
                  padding: '8px',
                  marginTop: '5px',
                  borderRadius: '4px',
                  border: '1px solid #ccc'
                }}
              />
            </label>
          </div>
          {buyinError && (
            <div style={{ color: 'red', marginBottom: '10px' }}>
              {buyinError}
            </div>
          )}
          <Button
            onClick={handleBuyinRequest}
            style={{ width: '100%' }}
          >
            Request Buy In
          </Button>
        </div>
      </Modal>

      <Modal
  isOpen={showStatusModal}
  onClose={() => setShowStatusModal(false)}
  title={statusMessage.title}
>
  <div style={{ padding: '20px' }}>
    <div style={{ 
      textAlign: 'center',
      marginBottom: '20px',
      color: statusMessage.type === 'success' ? '#4CAF50' : '#2196F3'
    }}>
      {statusMessage.type === 'success' ? (
        <span style={{ fontSize: '48px' }}>✓</span>
      ) : (
        <span style={{ fontSize: '48px' }}>ℹ</span>
      )}
    </div>
    <p style={{ 
      textAlign: 'center',
      fontSize: '16px',
      color: 'white',
      marginBottom: '20px'
    }}>
      {statusMessage.message}
    </p>
    
    {/* Add this block for buy-in request actions */}
    {statusMessage.buyinId ? (
      <div style={{ 
        display: 'flex', 
        gap: '10px', 
        marginTop: '20px' 
      }}>
        <Button
          onClick={async () => {
            try {
              console.log('Sending buyin approval request:', {
                gameId,
                buyinId: statusMessage.buyinId,
                token: user?.token
              });

              const response = await fetch(`${config.apiUrl}/api/games/${gameId}/buyins/${statusMessage.buyinId}/approve`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${user?.token}`
                },
                body: JSON.stringify({
                  gameId,
                  buyinId: statusMessage.buyinId,
                  userId: statusMessage.requestingUserId
                })
              });
              
              const data = await response.json();
              console.log('Buyin approval response:', {
                status: response.status,
                data
              });

              if (!response.ok) throw new Error(data.message || 'Failed to approve buyin');
              
              setShowStatusModal(false);
            } catch (error) {
              console.error('Error approving buyin:', error);
            }
          }}
          style={{
            flex: 1,
            backgroundColor: '#4CAF50'
          }}
        >
          Approve
        </Button>
        <Button
          onClick={() => {
            // Handle reject
            socket.emit('buyinResponse', {
              buyinId: statusMessage.buyinId,
              userId: statusMessage.requestingUserId,
              approved: false
            });
            setShowStatusModal(false);
          }}
          style={{
            flex: 1,
            backgroundColor: '#f44336'
          }}
        >
          Reject
        </Button>
      </div>
    ) : (
      <Button
        onClick={() => setShowStatusModal(false)}
        style={{
          width: '100%',
          backgroundColor: statusMessage.type === 'success' ? '#4CAF50' : '#2196F3'
        }}
      >
        OK
      </Button>
    )}
  </div>
</Modal>

      <ShowdownResult 
        winners={showdownResults} 
        show={gameState.phase === 'SHOWDOWN'} 
      />
      
      <TableFelt>
        <PotInfo>
          {gameState.status === GAME_STATUS.WAITING && !showCountdown ? (
            "Waiting for players..."
          ) : (
            <>
              <ChipStack chipCount={getChipStack(gameState.pot).length}>
                {getChipStack(gameState.pot).map((_, index) => (
                  <StyledChip
                    key={index}
                    index={index}
                    color={getChipColor(index)}
                  />
                ))}
              </ChipStack>
              Pot: {gameState.pot}
            </>
          )}
        </PotInfo>

        <CommunityCardsContainer>
          <CommunityCards 
            cards={gameState.communityCards}
            phase={gameState.phase}
          />
        </CommunityCardsContainer>

        {renderSeats()}
      </TableFelt>

      {/* Action buttons for active players */}
      {!isSpectator && isCurrentPlayerActive && 
       gameState.status === 'PLAYING' && 
       gameState.phase !== 'SHOWDOWN' && (
        <ActionButtons 
          isActive={true}
          onAction={handleAction}
          currentBet={gameState.currentBet}
          playerBet={currentPlayer?.currentBet || 0}
          playerChips={currentPlayer?.chips || 0}
          minRaise={gameState.currentBet * 2}
          ChipIcon={ChipIcon}
        />
      )}

      {/* Buy In and Rejoin buttons for inactive players */}
      {isInactivePlayer && (
        <InactivePlayerActions>
          <div style={{ 
            display: 'flex', 
            gap: '10px', 
            justifyContent: 'center',
            padding: '10px'
          }}>
            <Button
              onClick={() => setShowBuyinModal(true)}
              style={{
                background: 'rgba(46, 204, 113, 0.2)',
                color: '#2ecc71',
                border: '2px solid rgba(46, 204, 113, 0.3)'
              }}
            >
              Buy In
            </Button>
            <Button
              onClick={handleRejoin}
              disabled={!hasEnoughChips}
              style={{
                background: 'rgba(52, 152, 219, 0.2)',
                color: '#3498db',
                border: '2px solid rgba(52, 152, 219, 0.3)',
                opacity: !hasEnoughChips ? 0.5 : 1
              }}
            >
              Rejoin Game
            </Button>
          </div>
        </InactivePlayerActions>
      )}
    </TableWrapper>
  );
};

export default Table; 