import React from 'react';
import styled from 'styled-components';
import Card from '../Card/Card';
import Chip from '../Chip/Chip';

const ChipIcon = styled.svg`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  vertical-align: middle;
  fill: currentColor;
`;

const SeatWrapper = styled.div`
  background: linear-gradient(to bottom, #2c2c2c, #1a1a1a);
  border-radius: ${props => `clamp(4px, 1vw, 6px)`};
  padding: ${props => props.isCurrentPlayer ? 
    `clamp(6px, 1.5vw, 8px)` : 
    `clamp(4px, 1vw, 6px)`};
  width: ${props => props.isCurrentPlayer ? 
    `clamp(120px, 15%, 160px)` : // Using percentage of table width
    `clamp(100px, 12%, 140px)`}; // Using percentage of table width
  color: white;
  text-align: center;
  position: absolute;
  border: ${props => {
    if (props.isActive && props.isCurrentPlayer) return '3px solid #0096ff';
    if (props.isActive) return '2px solid #0096ff';
    return '1px solid rgba(255,255,255,0.15)';
  }};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: ${props => props.isCurrentPlayer ? 20 : 10};
  
  ${({ position, isCurrentPlayer }) => {
    const getPositions = (isMobile) => ({
      0: { 
        bottom: '5%', 
        left: '50%', 
        transform: 'translateX(-50%)' 
      },
      1: { 
        bottom: '25%', 
        left: '2%'
      },
      2: { 
        top: '25%', 
        left: '2%'
      },
      3: { 
        top: '5%', 
        left: '50%', 
        transform: 'translateX(-50%)' 
      },
      4: { 
        top: '25%', 
        right: '2%'
      },
      5: { 
        bottom: '25%', 
        right: '2%'
      }
    });

    const positions = getPositions(false);
    const mobilePositions = getPositions(true);

    return `
      ${Object.entries(positions[position] || {})
        .map(([key, value]) => `${key}: ${value};`)
        .join('\n')}

      @media (max-width: 768px) {
        width: ${isCurrentPlayer ? 
          `clamp(100px, 20%, 120px)` : // Mobile size relative to table
          `clamp(80px, 18%, 100px)`};  // Mobile size relative to table
        ${Object.entries(mobilePositions[position] || {})
          .map(([key, value]) => `${key}: ${value};`)
          .join('\n')}
      }
    `;
  }}
`;

const PlayerInfo = styled.div`
  .player-name {
    color: #ffd700;
    font-size: ${props => props.isCurrentPlayer ? 
      `clamp(12px, 2.2vw, 16px)` : 
      `clamp(10px, 2vw, 14px)`};
    font-weight: bold;
    margin-bottom: ${props => `clamp(2px, 0.5vw, 3px)`};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .stack {
    color: #00ff00;
    font-size: ${props => props.isCurrentPlayer ? 
      `clamp(11px, 2vw, 14px)` : 
      `clamp(9px, 1.8vw, 12px)`};
    margin-bottom: ${props => `clamp(2px, 0.8vw, 4px)`};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .status {
    font-size: ${props => props.isCurrentPlayer ? 
      `clamp(10px, 1.8vw, 13px)` : 
      `clamp(8px, 1.6vw, 11px)`};
  }
`;

const PlayerCards = styled.div`
  display: flex;
  gap: ${props => `clamp(2px, 0.5vw, 4px)`};
  position: relative;
  justify-content: center;
  transform-origin: center center;
  
  > div {
    &:first-child {
      transform: rotate(-8deg);
    }
    
    &:last-child {
      transform: rotate(8deg);
      margin-left: ${props => `clamp(-15px, -3vw, -12px)`};
    }
  }

  @media (max-width: 768px) {
    transform: scale(0.9);
    
    > div {
      &:last-child {
        margin-left: -10px;
      }
    }
  }
`;

const BetContainer = styled.div`
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 4px;
  z-index: 25;
`;

const ActionIndicator = styled.div`
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
`;

const TimerBar = styled.div`
  height: 2px;
  background: #0096ff;
  margin-top: 2px;
  width: ${props => props.timeLeft}%;
`;

const DealerButton = styled.div`
  position: absolute;
  top: -20px;
  right: -15px;
  transform: none;
  background: rgba(0, 0, 0, 0.8);
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
  z-index: 20;
`;

const EmptySeat = styled(SeatWrapper)`
  background: rgba(0, 0, 0, 0.3);
  border: 2px dashed rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
`;

const PlayerName = styled.div`
  font-weight: bold;
  color: #ffd700;
  font-size: 0.8rem;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
`;

const PlayerSeat = ({ 
  player, 
  position, 
  isActive, 
  isDealer, 
  isCurrentPlayer, 
  seatIndex,
  isShowdown,
  isActivePlayer 
}) => {
  if (!player) {
    return (
      <EmptySeat position={position}>
        <div>Seat {seatIndex + 1}</div>
      </EmptySeat>
    );
  }

  const {
    username,
    chips,
    currentBet,
    cards,
    folded,
    isAllIn
  } = player;

  // Determine if cards should be revealed
  const shouldRevealCards = 
    isCurrentPlayer || 
    (isShowdown && isActivePlayer && !folded);

  return (
    <SeatWrapper 
      position={position} 
      isActive={isActive} 
      isCurrentPlayer={isCurrentPlayer}
    >
      {isDealer && <DealerButton>D</DealerButton>}
      <PlayerInfo isCurrentPlayer={isCurrentPlayer}>
        <PlayerName>{username || 'Unknown'}</PlayerName>
        <div className="stack">
          <ChipIcon viewBox="0 0 24 24">
            <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0ZM22,12a10,10,0,0,1-.186,1.9l-1.932-.58a7.442,7.442,0,0,0,0-2.64l1.932-.58A10,10,0,0,1,22,12Zm-.76-3.816-1.93.579A8.048,8.048,0,0,0,15.237,4.69l.579-1.93A10.058,10.058,0,0,1,21.24,8.184ZM12,18a6,6,0,1,1,6-6A6.006,6.006,0,0,1,12,18ZM13.9,2.186l-.58,1.932a7.442,7.442,0,0,0-2.64,0L10.1,2.186a9.8,9.8,0,0,1,3.8,0ZM8.184,2.76l.579,1.93A8.048,8.048,0,0,0,4.69,8.763l-1.93-.579A10.058,10.058,0,0,1,8.184,2.76Z" />
          </ChipIcon>
          {chips || 0}
        </div>
        {folded && <div className="status">Folded</div>}
        {isAllIn && <div className="status">All In</div>}
      </PlayerInfo>
      
      {cards && cards.length > 0 && (
        <PlayerCards isCurrentPlayer={isCurrentPlayer}>
          {cards.map((card, index) => (
            <Card 
              key={index} 
              rank={card.rank} 
              suit={card.suit}
              size={isCurrentPlayer ? 'large' : 'small'}
              faceDown={!shouldRevealCards && (card.suit === 'hidden' || card.rank === 'hidden')}
            />
          ))}
        </PlayerCards>
      )}
      
      {currentBet > 0 && (
        <BetContainer>
          <Chip amount={currentBet} />
        </BetContainer>
      )}
    </SeatWrapper>
  );
};

export default PlayerSeat; 