import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const TimerWrapper = styled.div`
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  animation: fade 1s infinite;
  
  @keyframes fade {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.7; }
  }
`;

const CountdownTimer = ({ seconds, onComplete, startTime }) => {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    setTimeLeft(seconds);
  }, [seconds, startTime]);

  useEffect(() => {
    if (timeLeft <= 0) {
      onComplete();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft(prev => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onComplete]);

  return (
    <TimerWrapper>
      {timeLeft}
    </TimerWrapper>
  );
};

export default CountdownTimer; 