import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from '../ui/Modal';
import config from '../../config';

const BuyinHistory = ({ gameId, onClose }) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBuyinHistory = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/games/${gameId}/buyins/history`, {
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch buy-in history');
        }

        const data = await response.json();
        setHistory(data.success ? data.history : []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBuyinHistory();
  }, [gameId]);

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title="Buy-in History"
    >
      <HistoryContainer>
        {loading ? (
          <LoadingText>Loading history...</LoadingText>
        ) : error ? (
          <ErrorText>{error}</ErrorText>
        ) : history.length === 0 ? (
          <NoHistoryText>No buy-in history available</NoHistoryText>
        ) : (
          <HistoryList>
            {history.map((entry, index) => (
              <HistoryItem key={entry.buyinId || index} status={entry.status}>
                <UserInfo>
                  <Username>{entry.username}</Username>
                  <Amount>{entry.amount.toLocaleString()} chips</Amount>
                </UserInfo>
                <Details>
                  <Status status={entry.status}>
                    {entry.status.toUpperCase()}
                  </Status>
                  <Time>
                    {new Date(entry.timestamp).toLocaleString()}
                    {entry.approvedBy && ` • Approved by ${entry.approvedBy}`}
                  </Time>
                </Details>
              </HistoryItem>
            ))}
          </HistoryList>
        )}
      </HistoryContainer>
    </Modal>
  );
};

const HistoryContainer = styled.div`
  padding: 20px;
  max-height: 400px;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  border-radius: 12px;
`;

const HistoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const HistoryItem = styled.div`
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  border: 2px solid ${props => {
    switch (props.status) {
      case 'approved': return 'rgba(46, 204, 113, 0.3)';
      case 'pending': return 'rgba(241, 196, 15, 0.3)';
      case 'rejected': return 'rgba(231, 76, 60, 0.3)';
      default: return 'rgba(255, 255, 255, 0.2)';
    }
  }};
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const Username = styled.span`
  color: white;
  font-weight: bold;
`;

const Amount = styled.span`
  color: #2ecc71;
  font-weight: bold;
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Status = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  ${props => {
    switch (props.status) {
      case 'approved':
        return `
          background: rgba(46, 204, 113, 0.2);
          color: #2ecc71;
        `;
      case 'pending':
        return `
          background: rgba(241, 196, 15, 0.2);
          color: #f1c40f;
        `;
      case 'rejected':
        return `
          background: rgba(231, 76, 60, 0.2);
          color: #e74c3c;
        `;
      default:
        return `
          background: rgba(255, 255, 255, 0.1);
          color: white;
        `;
    }
  }}
`;

const Time = styled.span`
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const LoadingText = styled.div`
  color: white;
  text-align: center;
  padding: 20px;
`;

const ErrorText = styled.div`
  color: #e74c3c;
  text-align: center;
  padding: 20px;
`;

const NoHistoryText = styled.div`
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding: 20px;
`;

export default BuyinHistory; 