import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
  width: ${props => props.size === 'small' ? '40px' : '60px'};
  height: ${props => props.size === 'small' ? '56px' : '84px'};
  background: ${props => props.faceDown ? '#1a4b7c' : 'white'};
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  font-family: 'Arial', sans-serif;
  user-select: none;
  
  &:hover {
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    width: ${props => props.size === 'small' ? '30px' : '45px'};
    height: ${props => props.size === 'small' ? '42px' : '63px'};
    padding: 2px;
  }

  ${props => props.faceDown && `
    background: repeating-linear-gradient(
      45deg,
      #1a4b7c,
      #1a4b7c 10px,
      #15406b 10px,
      #15406b 20px
    );
    background-size: 8px 8px;
    border: 1px solid #15406b;
  `}
`;

const Corner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.position === 'top' ? 'flex-start' : 'flex-end'};
  font-size: ${props => props.size === 'small' ? '10px' : '14px'};
  font-weight: bold;
  color: ${props => props.color};
  line-height: 1;
`;

const Suit = styled.div`
  font-size: ${props => props.size === 'small' ? '8px' : '12px'};
  line-height: 1;
`;

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${props => props.size === 'small' ? '16px' : '24px'};
  color: ${props => props.color};
`;

const Card = ({ rank, suit, faceDown, size = 'normal' }) => {
  if (faceDown) {
    return <CardWrapper faceDown={true} size={size} />;
  }

  const suitSymbol = {
    'hearts': '♥',
    'diamonds': '♦',
    'clubs': '♣',
    'spades': '♠'
  }[suit.toLowerCase()];

  const color = ['hearts', 'diamonds'].includes(suit.toLowerCase()) ? '#e74c3c' : '#2c3e50';

  const displayRank = {
    '14': 'A',
    '13': 'K',
    '12': 'Q',
    '11': 'J',
    '10': '10'
  }[rank] || rank;

  return (
    <CardWrapper size={size}>
      <Corner position="top" color={color} size={size}>
        {displayRank}
        <Suit size={size}>{suitSymbol}</Suit>
      </Corner>
      <Center color={color} size={size}>{suitSymbol}</Center>
      <Corner position="bottom" color={color} size={size}>
        {displayRank}
        <Suit size={size}>{suitSymbol}</Suit>
      </Corner>
    </CardWrapper>
  );
};

export default Card; 