import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Landing = () => {
  const navigate = useNavigate();

  return (
    <LandingWrapper>
      <HeroSection>
        <BrandTitle>
          <span>HNI</span>
          <span className="accent">Poker</span>
        </BrandTitle>
        <HeroText>Host your own poker game. Invite friends. Have fun.</HeroText>
        <CTAButtons>
          <PrimaryButton onClick={() => navigate('/auth')}>
            Get Started
          </PrimaryButton>
          <SecondaryButton onClick={() => navigate('/auth')}>
            Login
          </SecondaryButton>
        </CTAButtons>
      </HeroSection>

      <FeaturesSection>
        <FeatureCard>
          <FeatureIcon>🎮</FeatureIcon>
          <FeatureTitle>Host Games</FeatureTitle>
          <FeatureText>
            Create and manage your own poker tables. Set buy-ins, blinds, and player limits.
          </FeatureText>
        </FeatureCard>

        <FeatureCard>
          <FeatureIcon>🔗</FeatureIcon>
          <FeatureTitle>Easy Invites</FeatureTitle>
          <FeatureText>
            Share a simple link with friends to join your game instantly.
          </FeatureText>
        </FeatureCard>

        <FeatureCard>
          <FeatureIcon>🎲</FeatureIcon>
          <FeatureTitle>Real-Time Play</FeatureTitle>
          <FeatureText>
            Enjoy smooth, real-time gameplay with friends from anywhere.
          </FeatureText>
        </FeatureCard>
      </FeaturesSection>

      <Footer>
        <FooterText>
          Made with ♠️ ♥️ ♣️ ♦️
        </FooterText>
      </Footer>
    </LandingWrapper>
  );
};

const LandingWrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%);
  color: white;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const HeroSection = styled.div`
  padding: 60px 20px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  
  @media (max-width: 480px) {
    padding: 40px 16px;
  }
`;

const BrandTitle = styled.h1`
  font-size: clamp(2.5rem, 8vw, 4rem);
  margin-bottom: 20px;
  
  span {
    font-weight: 700;
    display: inline-block;
    
    &.accent {
      color: #2ecc71;
      margin-left: 10px;
    }
  }
`;

const HeroText = styled.p`
  font-size: clamp(1.1rem, 4vw, 1.5rem);
  color: #cccccc;
  margin-bottom: 40px;
  line-height: 1.6;
  padding: 0 10px;
`;

const CTAButtons = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
  
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 15px;
    padding: 0 20px;
  }
`;

const Button = styled.button`
  padding: clamp(12px, 3vw, 15px) clamp(20px, 5vw, 30px);
  border-radius: 8px;
  font-size: clamp(1rem, 3vw, 1.1rem);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  width: fit-content;
  min-width: 150px;
  
  @media (max-width: 480px) {
    width: 100%;
  }
  
  &:hover {
    transform: translateY(-2px);
  }
  
  &:active {
    transform: translateY(1px);
  }
  
  @media (hover: none) {
    &:hover {
      transform: none;
    }
  }
`;

const PrimaryButton = styled(Button)`
  background: #2ecc71;
  color: white;
  border: none;
  
  &:hover {
    background: #27ae60;
  }
`;

const SecondaryButton = styled(Button)`
  background: transparent;
  color: #2ecc71;
  border: 2px solid #2ecc71;
  
  &:hover {
    background: rgba(46, 204, 113, 0.1);
  }
`;

const FeaturesSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 30px 16px;
    gap: 20px;
  }
`;

const FeatureCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: clamp(20px, 5vw, 30px);
  text-align: center;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.2s;
  
  @media (hover: hover) {
    &:hover {
      transform: translateY(-5px);
    }
  }
  
  @media (max-width: 480px) {
    padding: 20px;
  }
`;

const FeatureIcon = styled.div`
  font-size: clamp(2.5rem, 6vw, 3rem);
  margin-bottom: clamp(15px, 4vw, 20px);
`;

const FeatureTitle = styled.h3`
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  margin-bottom: 15px;
  color: #2ecc71;
`;

const FeatureText = styled.p`
  color: #cccccc;
  line-height: 1.6;
  font-size: clamp(0.9rem, 3vw, 1rem);
`;

const Footer = styled.footer`
  text-align: center;
  padding: clamp(20px, 5vw, 40px);
  margin-top: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const FooterText = styled.p`
  color: #666;
  font-size: clamp(0.8rem, 2.5vw, 1rem);
`;

export default Landing; 