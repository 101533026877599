import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Modal } from '../ui/Modal';
import config from '../../config';

// Use config.apiUrl instead of API_URL
const apiUrl = config.apiUrl;

const ChipIcon = () => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    xmlns="http://www.w3.org/2000/svg" 
    fill="currentColor"
  >
    <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0ZM22,12a10,10,0,0,1-.186,1.9l-1.932-.58a7.442,7.442,0,0,0,0-2.64l1.932-.58A10,10,0,0,1,22,12Zm-.76-3.816-1.93.579A8.048,8.048,0,0,0,15.237,4.69l.579-1.93A10.058,10.058,0,0,1,21.24,8.184ZM12,18a6,6,0,1,1,6-6A6.006,6.006,0,0,1,12,18ZM13.9,2.186l-.58,1.932a7.442,7.442,0,0,0,2.64,0L10.1,2.186a9.8,9.8,0,0,1,3.8,0ZM8.184,2.76l.579,1.93A8.048,8.048,0,0,0,4.69,8.763L2.76,8.184A10.058,10.058,0,0,1,8.184,2.76ZM2,12a10,10,0,0,1,.186-1.9l1.932.58a7.442,7.442,0,0,0,0,2.64l-1.932.58A10,10,0,0,1,2,12Zm.76,3.816,1.93-.579A8.048,8.048,0,0,0,8.763,19.31l-.579,1.93A10.058,10.058,0,0,1,2.76,15.816Zm7.34,6,.58-1.932a7.442,7.442,0,0,0,2.64,0l.58,1.932a9.8,9.8,0,0,1-3.8,0Zm5.716-.575-.579-1.929a8.048,8.048,0,0,0,4.073-4.073l1.93.579A10.053,10.053,0,0,1,15.816,21.239ZM14.631,10.892a1.848,1.848,0,0,1,0,2.216l-1.893,2.523a.922.922,0,0,1-1.476,0L9.369,13.108a1.848,1.848,0,0,1,0-2.216l1.893-2.523a.922.922,0,0,1,1.476,0Z" />
  </svg>
);

const InviteIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <circle cx="18" cy="5" r="3"/>
    <circle cx="6" cy="12" r="3"/>
    <circle cx="18" cy="19" r="3"/>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"/>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"/>
  </svg>
);

const Lobby = () => {
  const [myGames, setMyGames] = useState([]);
  const [chipBalance, setChipBalance] = useState(0);
  const { user, logout, updateUser } = useAuth();
  const navigate = useNavigate();
  const [showCreateGameModal, setShowCreateGameModal] = useState(false);
  const [selectedBuyIn, setSelectedBuyIn] = useState('');
  const [createGameError, setCreateGameError] = useState('');

  useEffect(() => {
    if (user) {
      fetchUserDetails();
      fetchMyGames();
      const interval = setInterval(fetchMyGames, 15000);
      return () => clearInterval(interval);
    }
  }, [user]);

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/games/user/details`, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setChipBalance(data.user.balance);
        if (updateUser) {
          updateUser({
            ...user,
            ...data.user,
            isAdmin: data.user.isAdmin
          });
        }
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  const fetchMyGames = async () => {
    try {
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
      
      const response = await fetch(
        `${apiUrl}/api/games/my-games?createdAfter=${twoDaysAgo.toISOString()}`, 
        {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      if (data.success && Array.isArray(data.games)) {
        const sortedGames = data.games.sort((a, b) => 
          new Date(b.createdAt) - new Date(a.createdAt)
        );
        setMyGames(sortedGames);
      }
    } catch (error) {
      console.error('Failed to fetch games:', error);
      setMyGames([]);
    }
  };

  const handleInvite = async (gameId, gameName) => {
    const inviteLink = `${window.location.origin}/join/${gameId}`;
    const shareData = {
      title: 'Join my Poker Game!',
      text: `Hey! Join my poker game at HNI Poker.`,
      url: inviteLink
    };

    try {
      // Try Web Share API first
      if (navigator.share) {
        await navigator.share(shareData);
        console.log('Shared successfully');
      } else {
        // Fallback to clipboard copy
        await navigator.clipboard.writeText(inviteLink);
        alert('Invite link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      // Final fallback for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = inviteLink;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        alert('Invite link copied to clipboard!');
      } catch (err) {
        console.error('Fallback: Failed to copy:', err);
        alert('Failed to copy invite link. Please copy manually: ' + inviteLink);
      }
      document.body.removeChild(textArea);
    }
  };


  const handleCreateGame = async () => {
    if (!selectedBuyIn) {
      setCreateGameError('Please select a buy-in amount');
      return;
    }

    const buyInAmount = Number(selectedBuyIn);
    const smallBlind = Math.max(1, Math.floor(buyInAmount * 0.01));
    const bigBlind = smallBlind * 2;

    try {
      const response = await fetch(`${apiUrl}/api/games/create-standard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          settings: {
            buyIn: buyInAmount,
            smallBlind,
            bigBlind,
            maxPlayers: 6
          }
        }),
        credentials: 'include'
      });

      const data = await response.json();
      if (data.success) {
        setShowCreateGameModal(false);
        setSelectedBuyIn('');
        setCreateGameError('');
        fetchMyGames();
      } else {
        setCreateGameError(data.message || 'Failed to create game');
      }
    } catch (error) {
      console.error('Failed to create game:', error);
      setCreateGameError('Failed to create game');
    }
  };

  const formatTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInHours = Math.floor((now - date) / (1000 * 60 * 60));
    
    if (diffInHours < 1) {
      const diffInMinutes = Math.floor((now - date) / (1000 * 60));
      return `${diffInMinutes}m ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours}h ago`;
    } else {
      const diffInDays = Math.floor(diffInHours / 24);
      return `${diffInDays}d ago`;
    }
  };

  return (
    <LobbyWrapper>
      <LobbyHeader>
        <HeaderContent>
          <BrandSection>
            <BrandTitle>HNI</BrandTitle>
            <BrandSubtitle>Poker</BrandSubtitle>
          </BrandSection>
          <UserSection>
            <ChipBalance>
              <ChipIcon />
              {chipBalance}
            </ChipBalance>
            {user?.isAdmin && (
              <HostViewButton onClick={() => navigate('/host')}>
                Admin View
              </HostViewButton>
            )}
            <LogoutButton onClick={logout}>Logout</LogoutButton>
          </UserSection>
        </HeaderContent>
      </LobbyHeader>

      <LobbyContent>
        <WelcomeSection>
          Welcome, {user?.username}
        </WelcomeSection>
        <GamesContainer>
                <SectionHeader>
          <HeaderContent>
            <h2>My Games</h2>
            <CreateGameButton onClick={() => setShowCreateGameModal(true)}>
              Create New Game
            </CreateGameButton>
          </HeaderContent>
        </SectionHeader>

          {myGames.length === 0 ? (
            <NoGamesMessage>You have no active games</NoGamesMessage>
          ) : (
            <GamesList>
              {myGames.map(game => (
                <GameCard key={game.gameId}>
                  <GameInfo>
                    <GameHeaderInfo>
                      <GameName>
                      Table #{game.gameId.toUpperCase().substring(0, 6)}
                        {game.hostId === user.id && (
                          <HostBadge>👑 Host</HostBadge>
                        )}
                      </GameName>
                      <GameStatus status={game.status}>
                        {game.status} ({game.players.length}/{game.settings.maxPlayers})
                      </GameStatus>
                    </GameHeaderInfo>
                    <GameDetails>
                      <SettingItem>Buy In: {game.settings.buyIn}</SettingItem>
                      <SettingItem>
                        Blinds: {game.settings.smallBlind}/{game.settings.bigBlind}
                      </SettingItem>
                      <SettingItem>
                        Created: {formatTimeAgo(game.createdAt)}
                      </SettingItem>
                    </GameDetails>
                    <PlayersList>
                      {game.players.map(player => (
                        <PlayerChip key={player.userId}>
                          {player.username}
                          {player.userId === game.hostId && <HostIndicator>👑</HostIndicator>}
                        </PlayerChip>
                      ))}
                    </PlayersList>
                  </GameInfo>
                  <GameActions>
                    {game.hostId === user.id && (
                      <InviteButton 
                        onClick={() => handleInvite(
                          game.gameId, 
                          `Table #${game.gameId}`
                        )}
                      >
                        <InviteIcon />
                        Share
                      </InviteButton>
                    )}
                    <JoinButton onClick={() => navigate(`/table/${game.gameId}`)}>
                      {game.hostId === user.id ? 'Host Game' : 'Return to Game'}
                    </JoinButton>
                  </GameActions>
                </GameCard>
              ))}
            </GamesList>
          )}
        </GamesContainer>
      </LobbyContent>

      <Modal
        isOpen={showCreateGameModal}
        onClose={() => {
          setShowCreateGameModal(false);
          setSelectedBuyIn('');
          setCreateGameError('');
        }}
        title="Select Buy In"
      >
        <div style={{ padding: '20px' }}>
          <div style={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(2, 1fr)', 
            gap: '12px',
            marginBottom: '20px'
          }}>
            {[500, 1000, 2000, 5000, 10000].map((amount) => (
              <button
                key={amount}
                onClick={() => setSelectedBuyIn(amount.toString())}
                style={{
                  padding: '16px',
                  background: selectedBuyIn === amount.toString() ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 255, 255, 0.1)',
                  border: `2px solid ${selectedBuyIn === amount.toString() ? '#2ecc71' : 'transparent'}`,
                  borderRadius: '8px',
                  color: 'white',
                  cursor: 'pointer'
                }}
              >
                {amount.toLocaleString()} chips
              </button>
            ))}
          </div>
          {createGameError && (
            <div style={{ color: '#e74c3c', marginBottom: '15px' }}>
              {createGameError}
            </div>
          )}
          <div style={{ 
            display: 'flex', 
            gap: '10px',
            marginTop: '20px'
          }}>
            <button
              onClick={() => {
                setShowCreateGameModal(false);
                setSelectedBuyIn('');
                setCreateGameError('');
              }}
              style={{
                flex: 1,
                padding: '12px',
                background: 'rgba(255, 255, 255, 0.1)',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer'
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleCreateGame}
              disabled={!selectedBuyIn}
              style={{
                flex: 1,
                padding: '12px',
                background: 'rgba(46, 204, 113, 0.2)',
                color: '#2ecc71',
                border: '2px solid rgba(46, 204, 113, 0.3)',
                borderRadius: '8px',
                cursor: 'pointer',
                opacity: !selectedBuyIn ? 0.5 : 1
              }}
            >
              Create Game
            </button>
          </div>
        </div>
      </Modal>
    </LobbyWrapper>
  );
};

// Updated and new styled components
const LobbyWrapper = styled.div`
  min-height: 100vh;
  background: #1a1a1a;
  color: white;
  padding: 20px;
`;

const LobbyHeader = styled.header`
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  padding: 15px 25px;
  border-radius: 15px;
  margin: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const BrandSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const BrandTitle = styled.h1`
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  line-height: 1;
`;

const BrandSubtitle = styled.span`
  color: rgba(255, 255, 255, 0.6);
  font-size: 1rem;
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ChipBalance = styled.div`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #ffd700;
  font-size: 1.1rem;
  
  svg {
    width: 18px;
    height: 18px;
    fill: currentColor;
  }
`;

const LogoutButton = styled.button`
  padding: 8px 16px;
  background: rgba(255, 68, 68, 0.1);
  color: #ff4444;
  border: 1px solid rgba(255, 68, 68, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background: rgba(255, 68, 68, 0.2);
  }
`;

const LobbyContent = styled.main`
  max-width: 1200px;
  margin: 0 auto;
`;

const WelcomeSection = styled.div`
  padding: 20px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
  margin-bottom: 10px;
  
  @media (max-width: 768px) {
    padding: 10px;
    font-size: 1rem;
  }
`;

const GamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const GamesSection = styled.section`
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  
  h2 {
    margin: 0;
    font-size: 1.8rem;
    color: #fff;
  }
`;

const NoGamesMessage = styled.div`
  text-align: center;
  padding: 40px;
  color: #888;
  font-size: 1.1rem;
`;

const GamesList = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const GameCard = styled.div`
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 20px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
  
  &:hover {
    background: rgba(0, 0, 0, 0.7);
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  }
`;

const GameInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GameHeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GameName = styled.h3`
  margin: 0;
  font-size: 1.2rem;
`;

const GameStatus = styled.span`
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 0.9rem;
  background: rgba(0, 123, 255, 0.2);
  color: #3498db;
  border: 1px solid rgba(52, 152, 219, 0.3);
  backdrop-filter: blur(5px);
`;

const GameDetails = styled.div`
  display: flex;
  gap: 15px;
`;

const SettingItem = styled.span`
  font-size: 0.9rem;
  color: #ddd;
`;

const PlayersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const PlayerChip = styled.span`
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.9);
`;

const GameActions = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

const JoinButton = styled.button`
  padding: 8px 16px;
  background: rgba(40, 167, 69, 0.2);
  color: #2ecc71;
  border: 1.5px solid rgba(46, 204, 113, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  backdrop-filter: blur(5px);
  
  &:hover {
    background: rgba(40, 167, 69, 0.3);
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(1px);
  }
`;

const CreateGameButton = styled.button`
  padding: 10px 20px;
  background: rgba(40, 167, 69, 0.2);
  color: #2ecc71;
  border: 1.5px solid rgba(46, 204, 113, 0.3);
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s;
  backdrop-filter: blur(5px);
  
  &:hover {
    background: rgba(40, 167, 69, 0.3);
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(1px);
  }
`;

const HostViewButton = styled.button`
  padding: 8px 16px;
  background: rgba(255, 215, 0, 0.2);
  color: #ffd700;
  border: 1.5px solid rgba(255, 215, 0, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  backdrop-filter: blur(5px);
  margin-right: 10px;
  
  &:hover {
    background: rgba(255, 215, 0, 0.3);
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(1px);
  }
`;

const HostBadge = styled.span`
  margin-left: 8px;
  font-size: 0.8em;
  color: #ffd700;
  background: rgba(255, 215, 0, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid rgba(255, 215, 0, 0.3);
`;

const HostIndicator = styled.span`
  margin-left: 4px;
  font-size: 0.8em;
`;

const InviteButton = styled.button`
  padding: 8px 16px;
  background: rgba(52, 152, 219, 0.2);
  color: #3498db;
  border: 1.5px solid rgba(52, 152, 219, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  gap: 6px;
  
  &:hover {
    background: rgba(52, 152, 219, 0.3);
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(1px);
  }

  @media (hover: none) {
    &:hover {
      transform: none;
    }
  }
`;

export default Lobby; 