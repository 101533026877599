import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import HandHistory from '../HandHistory/HandHistory';
import BuyinHistory from '../BuyinHistory/BuyinHistory';
import config from '../../config';

const apiUrl = config.apiUrl;

const MenuContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 9999;
  cursor: pointer;
  user-select: none;
  width: 45px;
  height: 45px;
`;

const MainButton = styled.button`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.6) !important;
  backdrop-filter: blur(5px);
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  transition: all 0.2s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background: rgba(0, 0, 0, 0.8) !important;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const MenuItems = styled.div`
  position: absolute;
  top: 55px;
  left: -5px;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 8px;
  display: ${props => props.isOpen ? 'block' : 'none'};
  min-width: 180px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  z-index: 3000;
  opacity: ${props => props.isOpen ? 1 : 0};
  transform: ${props => props.isOpen ? 'translateY(0)' : 'translateY(-10px)'};
  transition: all 0.2s ease-out;
`;

const MenuItem = styled.div`
  padding: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.15s;
  margin: 4px 0;

  .label {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    justify-content: center;
  }

  &:hover {
    transform: translateY(-1px);
    opacity: 0.9;
  }

  &:active {
    transform: translateY(1px);
  }
`;

const HamburgerIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z" />
  </svg>
);

const GameMenu = ({ currentPot = 0, isSoundEnabled, setIsSoundEnabled, gameId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showBuyinHistory, setShowBuyinHistory] = useState(false);
  const navigate = useNavigate();

  const handleLeaveTable = async () => {
    if (window.confirm('Are you sure you want to leave the table?')) {
      try {
        const response = await fetch(`${config.apiUrl}/api/games/${gameId}/leave`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          credentials: 'include'
        });

        if (response.ok) {
          navigate('/lobby');
        } else {
          console.error('Leave table failed');
          navigate('/lobby');
        }
      } catch (error) {
        console.error('Error leaving table:', error);
        navigate('/lobby');
      }
    }
  };

  const handleBuyinHistoryClick = () => {
    setShowBuyinHistory(true);
    setIsOpen(false);
  };

  return (
    <>
      <MenuContainer>
        <MainButton onClick={() => setIsOpen(!isOpen)}>
          <HamburgerIcon />
        </MainButton>
        <MenuItems isOpen={isOpen}>
          <MenuItem>
            <span className="label">
              <span>🎯</span>
              Current Pot
            </span>
            <span className="value">
              {currentPot}
            </span>
          </MenuItem>

          <MenuItem 
            onClick={() => setIsSoundEnabled(!isSoundEnabled)}
            style={{
              background: 'rgba(52, 152, 219, 0.2)',
              color: '#3498db',
              border: '2px solid rgba(52, 152, 219, 0.3)',
              marginTop: '8px'
            }}
          >
            <span className="label">
              <span>{isSoundEnabled ? '🔊' : '🔇'}</span>
              Sound {isSoundEnabled ? 'ON' : 'OFF'}
            </span>
          </MenuItem>

          <MenuItem 
            onClick={() => setShowHistory(true)}
            style={{
              background: 'rgba(46, 204, 113, 0.2)',
              color: '#2ecc71',
              border: '2px solid rgba(46, 204, 113, 0.3)',
              marginTop: '8px'
            }}
          >
            <span className="label">
              <span>📜</span>
              Hand History
            </span>
          </MenuItem>

          <MenuItem 
            onClick={handleBuyinHistoryClick}
            style={{
              background: 'rgba(241, 196, 15, 0.2)',
              color: '#f1c40f',
              border: '2px solid rgba(241, 196, 15, 0.3)',
              marginTop: '8px'
            }}
          >
            <span className="label">
              <span style={{ fontSize: '16px' }}>💰</span>
              Buy-in History
            </span>
          </MenuItem>

          <MenuItem 
            onClick={handleLeaveTable}
            style={{
              background: 'rgba(231, 76, 60, 0.2)',
              color: '#e74c3c',
              border: '2px solid rgba(231, 76, 60, 0.3)',
              marginTop: '8px'
            }}
          >
            <span className="label">
              <span>🚪</span>
              Leave Table
            </span>
          </MenuItem>
        </MenuItems>
      </MenuContainer>

      {showHistory && (
        <HandHistory 
          gameId={gameId} 
          onClose={() => setShowHistory(false)} 
        />
      )}

      {showBuyinHistory && (
        <BuyinHistory
          gameId={gameId}
          onClose={() => setShowBuyinHistory(false)}
        />
      )}
    </>
  );
};

export default GameMenu; 