import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import styled from 'styled-components';

const JoinGameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #1a1a1a;
    color: white;
`;

const LoadingMessage = styled.div`
    color: #fff;
    font-size: 1.2rem;
    margin: 1rem 0;
`;

const ErrorMessage = styled.div`
    color: #ff0000;
    font-size: 1.2rem;
    margin: 1rem 0;
`;

const JoinGame = () => {
    const { gameId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [error, setError] = useState(null);

    useEffect(() => {
        const joinGame = async () => {
            if (!user) return;

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:3001'}/api/games/${gameId}/join`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                });

                const data = await response.json();
                
                if (data.success) {
                    console.log('Successfully joined game, navigating to table...');
                    navigate(`/table/${gameId}`);
                } else {
                    setError(data.message);
                    setTimeout(() => navigate('/lobby'), 3000);
                }
            } catch (error) {
                console.error('Join game error:', error);
                setError('Failed to join game. Redirecting to lobby...');
                setTimeout(() => navigate('/lobby'), 3000);
            }
        };

        joinGame();
    }, [gameId, user, navigate]);

    if (error) {
        return (
            <JoinGameWrapper>
                <ErrorMessage>{error}</ErrorMessage>
            </JoinGameWrapper>
        );
    }

    return (
        <JoinGameWrapper>
            <LoadingMessage>Joining game as {user?.username}...</LoadingMessage>
        </JoinGameWrapper>
    );
};

export default JoinGame; 