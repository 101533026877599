import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(26, 26, 26, 0.95);
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  z-index: 2000;
  visibility: visible !important;
  opacity: 1 !important;
  max-width: 95vw;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 768px) {
    padding: 10px;
    gap: 6px;
    bottom: 10px;
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  background: ${props => props.variant === 'primary' ? '#3498db' : '#2c3e50'};
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 100px;

  &:hover {
    filter: brightness(1.1);
    transform: translateY(-1px);
  }

  &:disabled {
    background: #95a5a6;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
    min-width: 80px;
  }
`;

const TimerButton = styled(Button)`
  position: relative;
  overflow: hidden;
  background: #2c3e50;
  transition: background 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: ${props => 100 - props.progress}%;
    height: 100%;
    background: #e74c3c;
    transition: width 0.1s linear;
    z-index: 0;
  }

  span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  ${props => props.progress < 30 && `
    animation: pulse 1s infinite;
    @keyframes pulse {
      0% { transform: scale(1); }
      50% { transform: scale(1.05); }
      100% { transform: scale(1); }
    }
  `}
`;

const RaiseControls = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const RaiseInput = styled.input`
  width: 80px;
  padding: 8px;
  border: 2px solid #3498db;
  border-radius: 6px;
  background: rgba(44, 62, 80, 0.3);
  color: white;
  font-size: 14px;
  text-align: center;

  @media (max-width: 768px) {
    width: 60px;
    padding: 6px;
    font-size: 12px;
  }
`;

const PresetButton = styled.button`
  padding: 4px 6px;
  border: 1px solid #3498db;
  background: transparent;
  color: #3498db;
  border-radius: 3px;
  cursor: pointer;
  font-size: 11px;
  min-width: 35px;

  &:hover {
    background: #3498db;
    color: white;
  }
`;

const ActionButtons = ({ 
  isActive, 
  onAction, 
  currentBet, 
  playerBet, 
  playerChips, 
  isAllIn,
  gameState 
}) => {
  const [raiseAmount, setRaiseAmount] = useState(currentBet * 2);
  const [progress, setProgress] = useState(100);
  
  // Add constant for turn duration
  const TURN_DURATION = 60; // 60 seconds

  useEffect(() => {
    if (!isActive) {
      setProgress(100);
      return;
    }

    // Initialize timer when becoming active
    setProgress(100);
    const startTime = gameState?.timer?.startTime || Date.now();
    const duration = gameState?.timer?.duration || 60000; // Changed from 30000 to 60000

    const interval = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const remaining = Math.max(0, duration - elapsed);
      const progressPercent = (remaining / duration) * 100;
      setProgress(progressPercent);

      if (remaining === 0) {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [isActive, gameState?.timer]);

  const callAmount = currentBet - playerBet;
  const canCheck = currentBet === playerBet;
  const isBetGreaterThanChips = callAmount > playerChips;
  const minRaise = Math.max(currentBet * 2, currentBet + 20);
  const maxRaise = playerChips;
  const canRaise = playerChips >= minRaise && !isBetGreaterThanChips;

  const handleAction = (actionType, amount = 0) => {
    const actionPayload = {
      action: actionType,
      amount: Number(amount)
    };
    onAction(actionPayload);
  };

  const handlePresetClick = (multiplier) => {
    const baseAmount = currentBet > 0 ? currentBet : 20;
    const totalRaise = baseAmount * multiplier;
    const newAmount = Math.min(totalRaise, playerChips);
    setRaiseAmount(Math.max(minRaise, newAmount));
  };

  // All-in scenario
  if (isBetGreaterThanChips) {
    return (
      <ButtonContainer>
        <TimerButton 
          onClick={() => handleAction('FOLD', 0)}
          progress={progress}
          disabled={!isActive}
        >
          <span>
            Fold
            <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM8 7v10l9-5-9-5z"/>
            </svg>
            {Math.ceil(progress / 100 * TURN_DURATION)}s
          </span>
        </TimerButton>
        <Button onClick={() => handleAction('CALL', playerChips)} variant="primary">
          Call {playerChips}
        </Button>
        <Button onClick={() => handleAction('ALL_IN', playerChips)} variant="primary">
          All In ({playerChips})
        </Button>
      </ButtonContainer>
    );
  }

  // Regular betting scenarios
  return (
    <ButtonContainer>
      <TimerButton 
        onClick={() => handleAction('FOLD', 0)}
        progress={progress}
        disabled={!isActive}
      >
        <span>
          Fold
          <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM8 7v10l9-5-9-5z"/>
          </svg>
          {Math.ceil(progress / 100 * TURN_DURATION)}s
        </span>
      </TimerButton>
      
      {canCheck ? (
        <Button onClick={() => handleAction('CHECK', 0)}>Check</Button>
      ) : (
        <Button onClick={() => handleAction('CALL', callAmount)}>
          Call {callAmount}
        </Button>
      )}
      
      {canRaise && (
        <RaiseControls>
          <PresetButton 
            onClick={() => handlePresetClick(2)}
            disabled={currentBet * 2 > playerChips}
          >
            2x
          </PresetButton>
          <PresetButton 
            onClick={() => handlePresetClick(3)}
            disabled={currentBet * 3 > playerChips}
          >
            3x
          </PresetButton>
          <RaiseInput
            type="number"
            min={minRaise}
            max={maxRaise}
            value={raiseAmount}
            onChange={(e) => setRaiseAmount(Number(e.target.value))}
          />
          <Button 
            variant="primary"
            onClick={() => handleAction('RAISE', raiseAmount)}
            disabled={raiseAmount < minRaise || raiseAmount > maxRaise}
          >
            Raise to {raiseAmount}
          </Button>
        </RaiseControls>
      )}
    </ButtonContainer>
  );
};

export default ActionButtons; 