import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import config from '../../config';
import Card from '../Card/Card';
import WinnerCard from '../WinnerCard/WinnerCard';

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const DialogContent = styled.div`
  background: #1a1a1a;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
  position: relative;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background: #1a1a1a;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const CloseButton = styled.button`
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    transform: scale(1.1);
  }
`;

const HandCard = styled.div`
  background: #2a2a2a;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
`;

const HandHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  color: #fff;
  font-size: 1.1em;
`;

const HandInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const ActionList = styled.ul`
  list-style: none;
  padding: 0;
`;

const Action = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const CommunityCards = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 15px 0;
  padding: 15px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
`;

const WinnerSection = styled.div`
  background: rgba(255, 215, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
  margin: 15px 0;
  border: 1px solid rgba(255, 215, 0, 0.2);
`;

const WinnerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const WinnerName = styled.h3`
  color: #ffd700;
  margin: 0;
`;

const WinnerAmount = styled.div`
  font-size: 1.2em;
  color: #00ff00;
`;

const HandName = styled.div`
  color: #ffd700;
  font-style: italic;
  margin: 8px 0;
`;

const CardSection = styled.div`
  margin: 10px 0;
  
  h4 {
    color: #888;
    margin: 5px 0;
    font-size: 0.9em;
  }
`;

const Cards = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 8px 0;
`;

const PlayerSection = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const PlayerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const PlayerName = styled.h4`
  margin: 0;
  color: #fff;
`;

const ChipsInfo = styled.div`
  font-size: 0.9em;
  color: ${props => props.isPositive ? '#00ff00' : '#ff4444'};
`;

const WinByFold = styled.div`
  color: #ffd700;
  font-style: italic;
  text-align: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  margin: 10px 0;
`;

const SimpleCard = styled.div`
  width: 50px;
  height: 70px;
  border-radius: 4px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 0 4px;
`;

const CardCorner = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  line-height: 1;
`;

const CardRank = styled.span`
  font-size: 14px;
  color: ${props => props.isRed ? '#E31C1C' : '#000000'};
`;

const CardSuit = styled.span`
  font-size: 14px;
  color: ${props => props.isRed ? '#E31C1C' : '#000000'};
`;

const getSuitSymbol = (suit) => {
  switch (suit?.toLowerCase()) {
    case 'hearts': return '♥';
    case 'diamonds': return '♦';
    case 'clubs': return '♣';
    case 'spades': return '♠';
    default: return suit;
  }
};

const formatRank = (rank) => {
  switch (rank?.toLowerCase()) {
    case '10': return '10';
    case 'jack': return 'J';
    case 'queen': return 'Q';
    case 'king': return 'K';
    case 'ace': return 'A';
    default: return rank;
  }
};

const SimpleHandCard = ({ rank, suit }) => {
  const suitSymbol = getSuitSymbol(suit);
  const formattedRank = formatRank(rank);
  const isRed = suitSymbol === '♥' || suitSymbol === '♦';

  return (
    <SimpleCard>
      <CardCorner>
        <CardRank isRed={isRed}>{formattedRank}</CardRank>
        <CardSuit isRed={isRed}>{suitSymbol}</CardSuit>
      </CardCorner>
    </SimpleCard>
  );
};

const SectionTitle = styled.h4`
  color: #fff;
  margin: 10px 0;
  font-size: 1.1em;
`;

const apiUrl = config.apiUrl;

const HandHistory = ({ gameId, onClose }) => {
  const [hands, setHands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentHandIndex, setCurrentHandIndex] = useState(0);
  const currentUserId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchHandHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiUrl}/api/games/${gameId}/history`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          credentials: 'include'
        });
        
        const data = await response.json();
        console.log('Hand history data:', data);

        const formattedHands = data.hands.map(hand => ({
          id: hand._id,
          timestamp: hand.timestamp,
          phase: hand.phase,
          pot: hand.pot,
          communityCards: hand.communityCards || [],
          winners: hand.winners?.map(winner => ({
            username: winner.username,
            amount: winner.amount,
            handName: winner.handName,
            cards: winner.cards
          })),
          players: hand.players.map(player => ({
            userId: player.userId,
            username: player.username,
            initialChips: player.initialChips,
            finalChips: player.finalChips
          }))
        }));
        
        console.log('Formatted hands:', formattedHands);
        setHands(formattedHands);
      } catch (error) {
        console.error('Failed to fetch hand history:', error);
      } finally {
        setLoading(false);
      }
    };

    if (gameId) {
      fetchHandHistory();
    }
  }, [gameId]);

  // Close on escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') onClose();
    };
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  // Close on overlay click
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) onClose();
  };

  const goToPreviousHand = () => {
    if (currentHandIndex < hands.length - 1) {
      setCurrentHandIndex(currentHandIndex + 1);
    }
  };

  const goToNextHand = () => {
    if (currentHandIndex > 0) {
      setCurrentHandIndex(currentHandIndex - 1);
    }
  };

  const NavigationButton = styled.button`
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    border: 1.5px solid rgba(255, 255, 255, 0.2);
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    font-size: 24px;

    &:hover {
      background: rgba(0, 0, 0, 0.8);
      transform: scale(1.1);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        transform: none;
      }
    }
  `;

  const Navigation = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 0 20px;
  `;

  const HandCount = styled.div`
    color: #888;
    font-size: 14px;
  `;

  return (
    <DialogOverlay onClick={handleOverlayClick}>
      <DialogContent>
        <Header>
          <h1 style={{ color: '#fff' }}>Hand History</h1>
          <CloseButton onClick={onClose}>×</CloseButton>
        </Header>

        {loading ? (
          <div style={{ color: '#fff' }}>Loading hand history...</div>
        ) : hands.length > 0 ? (
          <>
            <HandCard>
              <HandHeader>
                <div>Pot: {hands[currentHandIndex].pot}</div>
                <div>Phase: {hands[currentHandIndex].phase}</div>
              </HandHeader>

              {/* Community Cards */}
              {hands[currentHandIndex].communityCards?.length > 0 && (
                <>
                  <SectionTitle>Community Cards</SectionTitle>
                  <CommunityCards>
                    {hands[currentHandIndex].communityCards.map((card, index) => (
                      <Card 
                        key={index}
                        rank={card.rank}
                        suit={card.suit}
                        size="medium"
                      />
                    ))}
                  </CommunityCards>
                </>
              )}

              {/* Winner Section */}
              {hands[currentHandIndex].winners?.map((winner, index) => {
                const isWinByFold = winner.handName === 'Default Win';
                
                return (
                  <WinnerSection key={index}>
                    <WinnerHeader>
                      <WinnerName>{winner.username}</WinnerName>
                      <WinnerAmount>+{winner.amount}</WinnerAmount>
                    </WinnerHeader>
                    
                    {winner.handName && !isWinByFold && (
                      <HandName>{winner.handName}</HandName>
                    )}
                    
                    {winner.cards && Array.isArray(winner.cards) && 
                     winner.cards.length > 0 && !isWinByFold && (
                      <>
                        <SectionTitle>Winning Hand</SectionTitle>
                        <Cards>
                          {winner.cards.map((card, cardIndex) => (
                            <SimpleHandCard
                              key={cardIndex}
                              rank={card.rank}
                              suit={card.suit}
                            />
                          ))}
                        </Cards>
                      </>
                    )}
                    
                    {isWinByFold && (
                      <WinByFold>Won by fold</WinByFold>
                    )}
                  </WinnerSection>
                );
              })}

              {/* Current User's Cards */}
              {hands[currentHandIndex].players?.find(player => 
                player.userId === currentUserId && player.cards?.length > 0
              ) && (
                <PlayerSection>
                  <SectionTitle>Your Hand</SectionTitle>
                  <Cards>
                    {hands[currentHandIndex].players
                      .find(player => player.userId === currentUserId)
                      .cards.map((card, index) => (
                        <SimpleHandCard
                          key={index}
                          rank={card.rank}
                          suit={card.suit}
                        />
                    ))}
                  </Cards>
                </PlayerSection>
              )}
            </HandCard>

            <Navigation>
              <NavigationButton 
                onClick={goToPreviousHand} 
                disabled={currentHandIndex >= hands.length - 1}
              >
                ←
              </NavigationButton>
              <HandCount style={{ color: '#fff' }}>
                Hand {hands.length - currentHandIndex} of {hands.length}
              </HandCount>
              <NavigationButton 
                onClick={goToNextHand} 
                disabled={currentHandIndex <= 0}
              >
                →
              </NavigationButton>
            </Navigation>
          </>
        ) : (
          <div style={{ color: '#fff' }}>No hand history available</div>
        )}
      </DialogContent>
    </DialogOverlay>
  );
};

export default HandHistory; 