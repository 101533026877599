import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import styled from 'styled-components';
import config from '../../config';

const HostView = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [hostedGames, setHostedGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user?.isAdmin) {
      navigate('/lobby');
      return;
    }

    const fetchHostedGames = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/games/hosted`, {
          credentials: 'include'
        });
        const data = await response.json();
        
        if (data.success) {
          setHostedGames(data.games);
        } else {
          setError(data.message || 'Failed to fetch hosted games');
        }
      } catch (error) {
        console.error('Failed to fetch hosted games:', error);
        setError('Failed to fetch hosted games');
      } finally {
        setIsLoading(false);
      }
    };

    fetchHostedGames();
  }, [user, navigate]);

  const handleGameClick = (gameId) => {
    navigate(`/host/table/${gameId}`);
  };

  const handleDeleteGame = async (gameId) => {
    if (!window.confirm('Are you sure you want to delete this game?')) return;

    try {
      const response = await fetch(`${config.apiUrl}/api/games/${gameId}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      const data = await response.json();

      if (data.success) {
        setHostedGames(hostedGames.filter(game => game.gameId !== gameId));
      } else {
        setError(data.message || 'Failed to delete game');
      }
    } catch (error) {
      console.error('Failed to delete game:', error);
      setError('Failed to delete game');
    }
  };

  if (error) return <ErrorMessage>{error}</ErrorMessage>;
  if (isLoading) return <LoadingMessage>Loading hosted games...</LoadingMessage>;

  return (
    <HostViewWrapper>
      <Header>
        <Title>Your Hosted Games</Title>
      </Header>

      <GamesContainer>
        {hostedGames.length === 0 ? (
          <NoGamesMessage>You haven't hosted any games yet.</NoGamesMessage>
        ) : (
          hostedGames.map((game) => (
            <GameCard 
              key={game.gameId} 
              onClick={() => handleGameClick(game.gameId)}
              status={game.status}
            >
              <GameHeader>
                <GameId>Table #{game.gameId}</GameId>
                <GameStatus status={game.status}>
                  {game.status}
                </GameStatus>
              </GameHeader>

              <GameDetails>
                <DetailItem>
                  <Label>Players:</Label>
                  <Value>{game.players.length}/{game.settings.maxPlayers}</Value>
                </DetailItem>
                <DetailItem>
                  <Label>Buy-in:</Label>
                  <Value>{game.settings.buyIn}</Value>
                </DetailItem>
                <DetailItem>
                  <Label>Blinds:</Label>
                  <Value>{game.settings.smallBlind}/{game.settings.bigBlind}</Value>
                </DetailItem>
              </GameDetails>

              <PlayersList>
                {game.players.map((player) => (
                  <PlayerItem key={player.userId}>
                    {player.username} - {player.chips}
                  </PlayerItem>
                ))}
              </PlayersList>

              <DeleteButton onClick={(e) => {
                e.stopPropagation(); // Prevent navigating to game view
                handleDeleteGame(game.gameId);
              }}>
                Delete Game
              </DeleteButton>
            </GameCard>
          ))
        )}
      </GamesContainer>
    </HostViewWrapper>
  );
};

// Styled components
const HostViewWrapper = styled.div`
  min-height: 100vh;
  background: #1a1a1a;
  color: white;
  padding: 20px;
`;

const Header = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #ffd700;
  margin: 0;
`;

const GamesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
`;

const GameCard = styled.div`
  background: ${({ status }) => 
    status === 'PLAYING' ? '#2d4a3e' : 
    status === 'FINISHED' ? '#3d3d3d' : '#2c3e50'};
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  transition: transform 0.2s;
  border: 1px solid #4a4a4a;
  position: relative;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
`;

const GameHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const GameId = styled.h2`
  margin: 0;
  font-size: 1.2rem;
  color: #ffd700;
`;

const GameStatus = styled.span`
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  background: ${({ status }) => 
    status === 'PLAYING' ? '#28a745' :
    status === 'FINISHED' ? '#6c757d' : '#007bff'};
`;

const GameDetails = styled.div`
  margin-bottom: 15px;
`;

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Label = styled.span`
  color: #aaa;
`;

const Value = styled.span`
  color: #fff;
`;

const PlayersList = styled.div`
  border-top: 1px solid #4a4a4a;
  padding-top: 15px;
  max-height: 150px;
  overflow-y: auto;
`;

const PlayerItem = styled.div`
  padding: 5px 0;
  color: #ddd;
  font-size: 0.9rem;
`;

const DeleteButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #c82333;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
`;

const LoadingMessage = styled.div`
  color: #fff;
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
`;

const NoGamesMessage = styled.div`
  grid-column: 1 / -1;
  text-align: center;
  color: #aaa;
  font-size: 1.2rem;
  padding: 40px;
`;

export default HostView; 