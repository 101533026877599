class SoundManager {
    constructor() {
      this.sounds = {
        cardDeal: new Audio('/sounds/card-deal.mp3'),
        check: new Audio('/sounds/check.mp3'),
        call: new Audio('/sounds/call.mp3'),
        raise: new Audio('/sounds/raise.mp3'),
        fold: new Audio('/sounds/fold.mp3'),
        win: new Audio('/sounds/win.mp3'),
        allIn: new Audio('/sounds/all-in.mp3'),
        shuffle: new Audio('/sounds/shuffle.mp3')
      };
  
      // Initialize all sounds with lower volume
      Object.values(this.sounds).forEach(sound => {
        sound.volume = 0.3;
        sound.load();
      });
    }
  
    play(soundName) {
      const sound = this.sounds[soundName];
      if (sound) {
        console.log(`Playing sound: ${soundName}`);
        sound.currentTime = 0; // Reset to start
        sound.play().catch(e => console.log('Sound play prevented:', e));
      }
    }
  
    // Stop all sounds
    stopAll() {
      Object.values(this.sounds).forEach(sound => {
        sound.pause();
        sound.currentTime = 0;
      });
    }
  }
  
  export default new SoundManager();