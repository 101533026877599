export const GAME_STATUS = {
  WAITING: 'WAITING',
  PLAYING: 'PLAYING',
  FINISHED: 'FINISHED'
};

export const GAME_PHASES = {
  PRE_FLOP: 'PRE_FLOP',
  FLOP: 'FLOP',
  TURN: 'TURN',
  RIVER: 'RIVER',
  SHOWDOWN: 'SHOWDOWN'
};

export const PLAYER_ACTIONS = {
  FOLD: 'fold',
  CHECK: 'check',
  CALL: 'call',
  RAISE: 'raise',
  ALL_IN: 'allIn'
};

export const initialGameState = {
  gameId: 'default',
  status: GAME_STATUS.WAITING,
  phase: GAME_PHASES.PRE_FLOP,
  pot: 0,
  currentBet: 0,
  dealerPosition: 1,
  activePlayerIndex: null,
  communityCards: [],
  players: Array(6).fill(null)
}; 