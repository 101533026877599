import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Card from '../Card/Card';

const CardsContainer = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  min-height: 96px; // Standard poker card height
`;

const CardWrapper = styled(motion.div)`
  position: relative;
`;

export const CommunityCards = ({ cards = [], phase }) => {
  const [animatingCards, setAnimatingCards] = useState([]);

  useEffect(() => {
    // Animate new cards when they appear
    if (cards.length > animatingCards.length) {
      const newCards = cards.slice(animatingCards.length);
      setAnimatingCards(prev => [...prev, ...newCards]);
    }
  }, [cards, animatingCards.length]);

  return (
    <CardsContainer>
      <AnimatePresence>
        {cards.map((card, index) => (
          <CardWrapper
            key={`${card.rank}${card.suit}`}
            initial={{ 
              scale: 0.8,
              opacity: 0 
            }}
            animate={{ 
              scale: 1,
              opacity: 1
            }}
            transition={{ 
              duration: 0.3,
              delay: index * 0.1,
              type: "spring",
              stiffness: 200
            }}
          >
            <Card 
              rank={card.rank} 
              suit={card.suit}
            />
          </CardWrapper>
        ))}
      </AnimatePresence>
    </CardsContainer>
  );
}; 