import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CardWrapper = styled.div`
  width: clamp(50px, 10vw, 70px);
  height: clamp(70px, 14vw, 100px);
  border-radius: clamp(4px, 1vw, 8px);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: clamp(4px, 1vw, 8px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin: 0 clamp(2px, 0.5vw, 4px);
  border: 1px solid #ddd;
  position: relative;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-out forwards;
  animation-delay: 0.1s;

  @media (hover: hover) {
    &:hover {
      transform: translateY(-5px);
    }
  }

  @media (max-width: 480px) {
    width: 45px;
    height: 63px;
    padding: 4px;
  }
`;

const Corner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.position === 'top' ? 'flex-start' : 'flex-end'};
  transform: ${props => props.position === 'bottom' ? 'rotate(180deg)' : 'none'};
  z-index: 1;
`;

const Rank = styled.span`
  font-size: clamp(14px, 2.5vw, 20px);
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  color: ${props => props.isRed ? '#E31C1C' : '#000000'};
  line-height: 1;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const SmallSuit = styled.span`
  font-size: clamp(12px, 2vw, 16px);
  color: ${props => props.isRed ? '#E31C1C' : '#000000'};
  line-height: 1;

  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

const CenterSuit = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(24px, 4.5vw, 36px);
  color: ${props => props.isRed ? '#E31C1C' : '#000000'};
  opacity: 0.8;

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const getSuitSymbol = (suit = '') => {
  if (!suit) return '?';
  
  switch (suit.toLowerCase()) {
    case 'hearts':
    case '♥':
      return '♥';
    case 'diamonds':
    case '♦':
      return '♦';
    case 'clubs':
    case '♣':
      return '♣';
    case 'spades':
    case '♠':
      return '♠';
    default:
      return '?';
  }
};

const formatRank = (rank = '') => {
  if (!rank) return '?';
  
  switch (rank.toString().toLowerCase()) {
    case '10':
      return '10';
    case 'jack':
    case 'j':
      return 'J';
    case 'queen':
    case 'q':
      return 'Q';
    case 'king':
    case 'k':
      return 'K';
    case 'ace':
    case 'a':
      return 'A';
    default:
      return rank;
  }
};

const WinnerCard = ({ rank, suit }) => {
  const suitSymbol = getSuitSymbol(suit);
  const formattedRank = formatRank(rank);
  const isRed = suitSymbol === '♥' || suitSymbol === '♦';
  
  return (
    <CardWrapper>
      <Corner position="top">
        <Rank isRed={isRed}>{formattedRank}</Rank>
        <SmallSuit isRed={isRed}>{suitSymbol}</SmallSuit>
      </Corner>
      <CenterSuit isRed={isRed}>{suitSymbol}</CenterSuit>
      <Corner position="bottom">
        <Rank isRed={isRed}>{formattedRank}</Rank>
        <SmallSuit isRed={isRed}>{suitSymbol}</SmallSuit>
      </Corner>
    </CardWrapper>
  );
};

export default WinnerCard; 