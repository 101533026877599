import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import styled from 'styled-components';
import io from 'socket.io-client';
import Card from '../Card/Card';
import config from '../../config';
import HandEvaluator from '../../utils/HandEvaluator';

const HostTableView = () => {
  const { gameId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [gameState, setGameState] = useState(null);
  const [socket, setSocket] = useState(null);
  const [error, setError] = useState(null);
  const handEvaluator = useMemo(() => new HandEvaluator(), []);

  useEffect(() => {
    if (!user?.isAdmin) {
      navigate('/lobby');
      return;
    }

    const newSocket = io(config.apiUrl, {
      withCredentials: true,
      query: { gameId, userId: user.id, isAdmin: true }
    });

    newSocket.on('connect', () => {
      newSocket.emit('joinAdminView', { gameId, userId: user.id });
    });

    const gameStateEvents = ['gameState', 'hostGameState', 'gameStateUpdate'];
    gameStateEvents.forEach(event => {
      newSocket.on(event, (state) => {

        console.log(`Received ${event}:`, state);
        if (state?.players) {
          console.log('Updating game state with players:', state.players.length);
          setGameState(prevState => ({
            ...prevState,
            ...state,
            players: [...state.players]  // Ensure we create a new array
          }));
        } else {
          setGameState(state);
        }

      });
    });

    newSocket.on('playerAction', () => {
      newSocket.emit('requestGameState', { gameId });
    });

    newSocket.on('error', (error) => {
      setError(error.message);
    });

    newSocket.emit('requestGameState', { gameId });

    setSocket(newSocket);

    return () => {
      if (newSocket) {
        gameStateEvents.forEach(event => newSocket.off(event));
        newSocket.disconnect();
      }
    };
  }, [gameId, user, navigate]);

  useEffect(() => {
    if (gameState?.players) {
      console.log('Processing player cards, total players:', gameState.players.length);
      gameState.players.forEach(player => {
        if (player.cards && player.cards.length > 0) {

          const evaluation = handEvaluator.evaluateHand(player.cards, gameState.communityCards);
          console.log(`Player ${player.username} cards:`, player.cards);
          console.log('Community cards:', gameState.communityCards);
          console.log('Evaluation:', evaluation);

          handEvaluator.evaluateHand(player.cards, gameState.communityCards);

        }
      });
    }
  }, [gameState, handEvaluator, gameState?.players]);

  if (error) return <ErrorMessage>{error}</ErrorMessage>;
  if (!gameState) return <LoadingMessage>Loading game state...</LoadingMessage>;

  // Define fixed seat positions with angles
  const FIXED_SEATS = [
    { angle: 270, position: 0 },  // Top
    { angle: 330, position: 1 },  // Top Right
    { angle: 30, position: 2 },   // Bottom Right
    { angle: 90, position: 3 },   // Bottom
    { angle: 150, position: 4 },  // Bottom Left
    { angle: 210, position: 5 },  // Top Left
  ];

  // Increase the radius for more spacing between seats
  const radius = Math.min(window.innerWidth * 0.35, window.innerHeight * 0.4);

  // Add a function to calculate win probability
  const calculateWinProbability = (playerCards, communityCards = []) => {
    if (!playerCards || !Array.isArray(playerCards) || playerCards.length !== 2) {
      return 0;
    }

    try {
      const allPlayerCards = gameState.players
        .filter(p => p.cards && p.cards.length === 2)
        .map(p => p.cards);

      return handEvaluator.calculateWinProbability(
        playerCards, 
        communityCards || [], 
        allPlayerCards
      );
    } catch (error) {
      return 0;
    }
  };

  // First, define all styled components
  const PlayerBoxContainer = styled.div`
    width: 140px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  const WinProgressBar = styled.div`
    height: 20px;
    width: 140px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 4px;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  `;

  const WinProgressFill = styled.div`
    height: 100%;
    width: ${props => props.percentage}%;
    background: ${props => {
      const prob = Number(props.percentage);
      if (prob >= 100) return '#2ecc71';
      if (prob > 70) return '#4caf50';
      if (prob > 40) return '#ffd700';
      return '#ff4444';
    }};
    transition: width 0.3s ease-in-out;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2);
  `;

  const WinPercentage = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 0.85rem;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 4px;
    letter-spacing: 0.5px;
    white-space: nowrap;
  `;

  const WinLabel = styled.span`
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.9);
  `;

  const PlayerBox = styled.div`
    background: ${({ isActive }) => isActive ? '#2d4a3e' : '#2c3e50'};
    border-radius: 4px;
    padding: 8px;
    border: 1px solid ${({ isActive }) => isActive ? '#4caf50' : '#4a4a4a'};
    width: 180px;
    height: 156px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    transform: scale(1.0);
  `;

  const PlayerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;  // Align to top for action display
    width: 100%;
    gap: 4px;
  `;

  const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;
  `;

  const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2px;
  `;

  const PlayerName = styled.div`
    font-size: 0.85rem;
    color: #ffd700;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  const PlayerChips = styled.div`
    font-size: 0.95rem;
    color: #4caf50;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 2px;
    line-height: 1;
    white-space: nowrap;
    
    &::before {
      content: '⌾';
      font-size: 0.8rem;
    }
  `;

  const PlayerCards = styled.div`
    display: flex;
    gap: 4px;
    justify-content: center;
    transform: scale(1.0);
    margin: -4px 0;
  `;

  const HandType = styled.div`
    color: #ffd700;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    background: rgba(0, 0, 0, 0.2);
    padding: 2px 4px;
    border-radius: 3px;
    text-align: center;
    line-height: 1;
    width: calc(100% - 12px);  // Account for parent padding
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  const StatusTag = styled.span`
    padding: 2px 4px;
    border-radius: 2px;
    font-size: 0.7rem;
    background: ${({ type }) => {
      switch (type) {
        case 'bet': return '#28a745';
        default: return '#6c757d';
      }
    }};
  `;

  const ChipStack = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    gap: -10px;
    z-index: 3;
    
    ${({ position }) => {
      switch(position) {
        case 0: // Top
          return `
            bottom: 80%;
            left: 50%;
            transform: translateX(-50%);
          `;
        case 1: // Top Right
          return `
            bottom: 60%;
            left: 80%;
            transform: translateX(-50%);
          `;
        case 2: // Bottom Right
          return `
            top: 60%;
            left: 80%;
            transform: translateX(-50%);
          `;
        case 3: // Bottom
          return `
            top: 80%;
            left: 50%;
            transform: translateX(-50%);
          `;
        case 4: // Bottom Left
          return `
            top: 60%;
            left: 20%;
            transform: translateX(-50%);
          `;
        case 5: // Top Left
          return `
            bottom: 60%;
            left: 20%;
            transform: translateX(-50%);
          `;
      }
    }}
  `;

  const Chip = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: ${props => {
      switch(props.value) {
        case 1000: return 'linear-gradient(135deg, #000000, #333333)';
        case 500: return 'linear-gradient(135deg, #9c27b0, #673ab7)';
        case 100: return 'linear-gradient(135deg, #000000, #444444)';
        case 25: return 'linear-gradient(135deg, #4caf50, #2e7d32)';
        case 5: return 'linear-gradient(135deg, #f44336, #d32f2f)';
        default: return 'linear-gradient(135deg, #ffd700, #ffa000)';
      }
    }};
    border: 2px dashed ${props => {
      switch(props.value) {
        case 1000: return '#gold';
        case 500: return '#e1bee7';
        case 100: return '#silver';
        case 25: return '#a5d6a7';
        case 5: return '#ffcdd2';
        default: return '#fff59d';
      }
    }};
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    color: white;
    font-weight: bold;
    text-shadow: 0 1px 1px rgba(0,0,0,0.5);
    margin-left: -10px;
    position: relative;
    z-index: ${props => props.index};

    &:first-child {
      margin-left: 0;
    }

    &::after {
      content: '${props => props.value}';
      position: absolute;
      font-size: 0.6rem;
    }
  `;

  const BetAmount = styled.div`
    position: absolute;
    color: #ffd700;
    font-size: 0.9rem;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.6);
    padding: 2px 8px;
    border-radius: 12px;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
    white-space: nowrap;
    z-index: 4;
    
    ${({ position }) => {
      switch(position) {
        case 0: return 'bottom: 85%; left: 50%; transform: translateX(-50%);';
        case 1: return 'bottom: 65%; left: 80%; transform: translateX(-50%);';
        case 2: return 'top: 65%; left: 80%; transform: translateX(-50%);';
        case 3: return 'top: 85%; left: 50%; transform: translateX(-50%);';
        case 4: return 'top: 65%; left: 20%; transform: translateX(-50%);';
        case 5: return 'bottom: 65%; left: 20%; transform: translateX(-50%);';
      }
    }}
  `;

  // Update the hand display logic
  const getHandDisplay = (playerCards, communityCards = []) => {
    if (!playerCards || playerCards.length !== 2) return '';
    
    try {
      const handEvaluation = handEvaluator.evaluatePlayerHand(playerCards, communityCards);
      return handEvaluation.name;
    } catch (error) {
      console.error("Error getting hand display:", error);
      return '';
    }
  };

  // Helper function to format rank
  const formatRank = (rank) => {
    const rankMap = {
      14: 'A',
      13: 'K',
      12: 'Q',
      11: 'J',
      10: '10'
    };
    return rankMap[rank] || rank.toString();
  };

  // Helper function to convert bet amount to chip stacks
  const getChipStacks = (amount) => {
    const denominations = [1000, 500, 100, 25, 5, 1];
    const chips = [];
    let remaining = amount;

    denominations.forEach(value => {
      const count = Math.floor(remaining / value);
      for (let i = 0; i < Math.min(count, 3); i++) {  // Max 3 chips per denomination
        chips.push(value);
      }
      remaining %= value;
    });

    return chips;
  };

  // Add this styled component for the action display
  const PlayerActionDisplay = styled.div`
    position: absolute;
    font-size: 0.7rem;
    color: #ff9800;
    font-weight: bold;
    padding: 1px 4px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    white-space: nowrap;
    z-index: 2;
    
    ${({ seatIndex }) => {
      switch(seatIndex) {
        case 0: // Seat 1
          return `
            bottom: -25px;
            left: 50%;
            transform: translateX(-50%);
          `;
        case 1: // Seat 2
          return `
            top: -25px;
            right: 0;
          `;
        case 2: // Seat 3
          return `
            top: -25px;
            left: 50%;
            transform: translateX(-50%);
          `;
        case 3: // Seat 4
          return `
            top: 50%;
            right: -25px;
            transform: translateY(-50%);
          `;
        case 4: // Seat 5
        case 5: // Seat 6
          return `
            bottom: -25px;
            left: 50%;
            transform: translateX(-50%);
          `;
      }
    }}
  `;

  // Add this styled component for the zoom effect
  const WinningCards = styled(PlayerCards)`
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
    z-index: 10;
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.5); // Golden glow
    border: 2px solid #ffd700;
  `;

  // Add this styled component for the watermark
  const TableWatermark = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    font-size: 4rem;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.1);
    white-space: nowrap;
    pointer-events: none;
    z-index: 1;
    font-family: 'Arial Black', sans-serif;
    text-transform: uppercase;
    letter-spacing: 4px;
  `;

  // Add this styled component for empty seats
  const EmptySeatBox = styled.div`
    background: rgba(44, 62, 80, 0.3);
    border: 2px dashed rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    width: 180px;
    height: 156px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  `;

  return (
    <TableWrapper>
      <GameInfo>
        <InfoItem>Status: {gameState.status}</InfoItem>
        <InfoItem>Phase: {gameState.phase}</InfoItem>
        <InfoItem>Current Bet: {gameState.currentBet}</InfoItem>
        <InfoItem>Pot: {gameState.pot}</InfoItem>
      </GameInfo>

      <VirtualTable>
        <TableWatermark>HNI.App</TableWatermark>
        <TablePot>{gameState.pot}</TablePot>
        
        {/* Add chip stacks for each player's bet */}
        {/* {gameState.players.map((player, index) => {
          if (player.currentBet > 0) {
            const chips = getChipStacks(player.currentBet);
            return (
              <React.Fragment key={`chips-${player.seatIndex}`}>
                <ChipStack position={player.seatIndex}>
                  {chips.map((value, chipIndex) => (
                    <Chip 
                      key={`chip-${chipIndex}`} 
                      value={value}
                      index={chips.length - chipIndex}
                    />
                  ))}
                </ChipStack>
                <BetAmount position={player.seatIndex}>
                  {player.currentBet}
                </BetAmount>
              </React.Fragment>
            );
          }
          return null;
        })} */}

        <CommunityCardsSection>
          <CardDisplay>
            {gameState.communityCards.map((card, index) => (
              <Card 
                key={index}
                rank={card.rank}
                suit={card.suit}
                size="large"
              />
            ))}
          </CardDisplay>
        </CommunityCardsSection>

        <PlayerPositions>
          {FIXED_SEATS.map((seat) => {
            const player = gameState.players.find(p => p.seatIndex === seat.position);
            console.log(`Seat ${seat.position}:`, player);
            
            return (
              <PlayerPosition 
                key={seat.position} 
                angle={seat.angle}
                radius={radius}
              >
                {player ? (
                  <>
                    {player.lastAction && (
                      <PlayerActionDisplay seatIndex={seat.position}>
                        {player.lastAction === 'bet' ? `Bet: ${player.currentBet}` : 
                         player.lastAction === 'call' ? `Call: ${player.currentBet}` : 
                         player.lastAction}
                      </PlayerActionDisplay>
                    )}
                    <PlayerBoxContainer>
                      <WinProgressBar>
                        <WinProgressFill 
                          percentage={calculateWinProbability(player.cards, gameState.communityCards)} 
                        />
                        <WinPercentage>
                          <WinLabel>WIN:</WinLabel>
                          {calculateWinProbability(player.cards, gameState.communityCards).toFixed(1)}%
                        </WinPercentage>
                      </WinProgressBar>
                      
                      <PlayerBox isActive={gameState.activePlayerIndex === player.seatIndex}>
                        <PlayerHeader>
                          <LeftSection>
                            <PlayerName>{player.username}</PlayerName>
                          </LeftSection>
                          <RightSection>
                            <PlayerChips>{player.chips}</PlayerChips>
                          </RightSection>
                        </PlayerHeader>
                        
                        {gameState.winners?.some(winner => winner.userId === player.userId) ? (
                          <WinningCards>
                            {player.cards.map((card, index) => (
                              <Card 
                                key={index}
                                rank={card.rank}
                                suit={card.suit}
                                size="medium"
                              />
                            ))}
                          </WinningCards>
                        ) : (
                          <PlayerCards>
                            {player.cards.map((card, index) => (
                              <Card 
                                key={index}
                                rank={card.rank}
                                suit={card.suit}
                                size="medium"
                              />
                            ))}
                          </PlayerCards>
                        )}
                        
                        <HandType>
                          {getHandDisplay(player.cards, gameState.communityCards)}
                        </HandType>
                      </PlayerBox>
                    </PlayerBoxContainer>
                    
                                  {player.currentBet > 0 && (
                <>
                  <ChipStack seatIndex={seat.position}>
                    {getChipStacks(player.currentBet).map((value, index) => (
                      <Chip 
                        key={index} 
                        value={value} 
                        seatIndex={seat.position}
                      >
                        {value}
                      </Chip>
                    ))}
                  </ChipStack>
                  <BetAmount seatIndex={seat.position}>
                    {player.currentBet}
                  </BetAmount>
                </>
              )}
                              
                  </>
                ) : (
                  <PlayerBoxContainer>
                    <EmptySeatBox>
                      Seat {seat.position + 1}
                    </EmptySeatBox>
                  </PlayerBoxContainer>
                )}
              </PlayerPosition>
            );
          })}
        </PlayerPositions>
      </VirtualTable>
    </TableWrapper>
  );
};

// Styled components
const TableWrapper = styled.div`
  min-height: 100vh;
  background: #1a1a1a;
  color: white;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
`;

const GameInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #2c3e50;
  padding: 20px;
  border-radius: 8px;
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  min-width: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #34495e;
  z-index: 5;
`;

const InfoItem = styled.div`
  color: #ffd700;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  &::before {
    content: '⌾';
    margin-right: 8px;
    color: #4caf50;
  }
`;

const SectionTitle = styled.h2`
  color: #ffd700;
  margin-bottom: 1rem;
`;

const CommunityCardsSection = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const CardDisplay = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  
  & > * {
    transform: scale(0.8);
  }
`;

const PlayersSection = styled.div`
  margin-bottom: 2rem;
`;

const PlayersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 12px;
  max-width: 1600px;
  margin: 0 auto;
`;

const LoadingMessage = styled.div`
  color: #fff;
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #333;
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
  margin-top: 4px;
`;

const ProgressBar = styled.div`
  height: 100%;
  background-color: ${props => props.probability > 70 ? '#4caf50' : props.probability > 40 ? '#ffd700' : '#ff4444'};
  width: ${props => props.probability}%;
  transition: width 0.3s ease-in-out;
`;

const VirtualTable = styled.div`
  position: relative;
  width: min(95vw, 1200px);
  height: min(80vh, 800px);
  background: #35654d;
  border-radius: min(8vw, 100px);
  border: clamp(5px, 1.5vw, 15px) solid #2c3e50;
  margin: 20px auto;
  overflow: visible;  // Allow seats to overflow
`;

const TablePot = styled.div`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffd700;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  gap: 4px;
  
  &::before {
    content: 'POT:';
    color: #ffffff;
    font-size: 1.2rem;
  }

  &::after {
    content: '';
    font-size: 1.2rem;
    color: #4caf50;
  }
`;

const PlayerPositions = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const PlayerPosition = styled.div`
  position: absolute;
  transform: ${props => `rotate(${props.angle}deg) translate(${props.radius}px) rotate(-${props.angle}deg)`};
  transform-origin: center;
  left: 50%;
  top: 50%;
  margin-left: -90px;
  margin-top: -78px;
`;

const EmptySeat = styled.div`
  background: rgba(44, 62, 80, 0.5);
  border-radius: 4px;
  padding: 12px;
  border: 1px dashed #4a4a4a;
  color: #666;
  font-size: 1rem;
  width: 200px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default HostTableView;