import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Auth from './components/Auth/Auth';
import Lobby from './components/Lobby/Lobby';
import Table from './components/Table/Table';
import JoinGame from './components/JoinGame/JoinGame';
import HostView from './components/HostView/HostView';
import HostTableView from './components/HostView/HostTableView';
import Landing from './components/Landing/Landing';

// Update the existing ProtectedRoute component
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    // Store the attempted URL in localStorage
    localStorage.setItem('redirectAfterLogin', location.pathname);
    // Pass the location in state for immediate access
    return <Navigate to="/auth" state={{ from: location.pathname }} />;
  }

  return children;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
        <Route path="/" element={<Landing />} />
          <Route path="/auth" element={<Auth />} />
          <Route 
            path="/lobby" 
            element={
              <ProtectedRoute>
                <Lobby />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/table/:gameId" 
            element={
              <ProtectedRoute>
                <Table />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/join/:gameId" 
            element={
              <ProtectedRoute>
                <JoinGame />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/host" 
            element={
              <ProtectedRoute>
                <HostView />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/host/table/:gameId" 
            element={
              <ProtectedRoute>
                <HostTableView />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App; 